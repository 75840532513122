import React, { Fragment, useState, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Logo from "../assets/images/rot3-logo.svg"
import { MobileMenu } from './NavbarMobile';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {

  const timerRef = useRef(null);
  const [activeItem, setActiveItem] = useState(null)

  const onClickHandler = (e) => {
    setActiveItem(e.currentTarget.name)
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }

  const subItemHover = (name) => {
    setActiveItem(name)
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }
  const activeItemRemover = () => {
    timerRef.current = setTimeout(() => {
      setActiveItem(null)
    }, 300);
  }

  return (
    <>

      <div className='w-full z-20 h-20 items-center  bg-white text-rot3-deep-green body2 lg:visible invisible hidden lg:flex'>
        <div className='w-full max-w-[1440px] m-auto justify-between lg:flex px-36' >
          <a href="/"><img className='pt-2' src={Logo} alt="" /></a>
          <ul className='flex z-20 items-center'>
            <a href="/"><li className='p-3 hover:text-rot3-malachite'>Home</li></a>
            <a href="/about"><li className='p-3 hover:text-rot3-malachite'>About</li></a>
            {/* <a href="/news"><li className='p-3 hover:text-rot3-malachite'>News</li></a> */}
            
            <li className=''>
              <Menu as='div' className='relative inline-block text-left'>
                <Menu.Button className='p-3 hover:text-rot3-malachite'>Solutions▾</Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-100'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gradient-to-b from-rot3-malachite to-[#A0FFDD] divide-y divide-gray-100 focus:outline-none'>
                    <div className='py-1 relative'>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test'
                            href='#'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Blockchain Solutions
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test2'
                            href='#'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Data Provider
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='rotpay'
                            href='/rotpay'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Rotpay
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='rot3wallet'
                            href='/rot3wallet'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Rot3 Wallet
                          </a>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test3'
                            href='#'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Forex Solutions
                          </a>
                        )}
                      </Menu.Item> */}
                      {activeItem === 'test' ?
                        <div className='absolute -top-2 -right-[230px] rounded-md shadow-lg bg-gradient-to-b w-[223px]'
                          onMouseEnter={() => { subItemHover('test') }}
                          onMouseLeave={activeItemRemover}
                        >
                          <Menu.Items className='origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gradient-to-b from-rot3-header-bg1 to-rot3-header-bg2  divide-y divide-gray-100 focus:outline-none' onMouseEnter={() => { subItemHover('test') }}>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/blockchain-solutions/solutions'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Cryptocurrency Exchange
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/blockchain-solutions/blockchain'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Blockchain
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/blockchain-solutions/nft-wallet-services'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Nft Wallet Services
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </div>
                        : <></>
                      }
                      {activeItem === 'test2' ?
                        <div className='absolute top-8 -right-[230px] rounded-md shadow-lg bg-gradient-to-b w-[223px]'
                          onMouseEnter={() => { subItemHover('test2') }}
                          onMouseLeave={activeItemRemover}
                        >
                          <Menu.Items className='origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gradient-to-b from-rot3-header-bg1 to-rot3-header-bg2 divide-y divide-gray-100 focus:outline-none'
                            onMouseEnter={() => { subItemHover('test2') }}>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/data-provider'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Stock
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/data-provider'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Forex
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/data-provider'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Cyrpto
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/data-provider'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Indexes
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/data-provider'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  EFTs
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </div>
                        : <></>
                      }

                      {activeItem === 'test3' ?
                        <div className='absolute top-[70px] -right-[230px] rounded-md shadow-lg bg-gradient-to-b w-[223px]'
                          onMouseEnter={() => { subItemHover('test3') }}
                          onMouseLeave={activeItemRemover}
                        >
                          <Menu.Items className='origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gradient-to-b from-rot3-header-bg1 to-rot3-header-bg2 divide-y divide-gray-100 focus:outline-none'
                            onMouseEnter={() => { subItemHover('test3') }}>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/forex-solutions/forex-legal-solutions'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Legal Solutions
                                </a>
                              )}
                            </Menu.Item> */}
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='/forex-solutions/forex-tech-solutions'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Technology Solutions
                                </a>
                              )}
                            </Menu.Item> */}
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='#'
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Financial Solutions
                                </a>
                              )}
                            </Menu.Item> */}
                          </Menu.Items>
                        </div>
                        : <></>
                      }


                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>

            <li>
            <Menu as='div' className='relative inline-block text-left'>
                <Menu.Button className='p-3 hover:text-rot3-malachite'>Marketing▾</Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-100'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gradient-to-b from-rot3-malachite to-[#A0FFDD] divide-y divide-gray-100 focus:outline-none'>
                    <div className='py-1 relative'>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test'
                            href='https://fitechpro.com/'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Marketing Platform
                          </a>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test2'
                            href='/marketing/marketing-platform'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            E-Mail Marketing
                          </a>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test3'
                            href='https://fitechpro.com/gamification'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Gamification
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            name='test3'
                            href='/marketing/logo-website-design'
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onMouseEnter={onClickHandler}
                            onMouseLeave={activeItemRemover}
                          >
                            Logo - Website Design
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
            <a href="/careers"><li className='p-3 hover:text-rot3-malachite'>Careers</li></a>
            <a href="/contact-us"><li className='p-3 hover:text-rot3-malachite'>Contact Us</li></a>
            <a className='text-rot3-malachite' href="/"><button className='border-rot3-deep-green bg-rot3-deep-green border-2 p-2 rounded-lg hover:bg-rot3-malachite hover:text-rot3-deep-green'>Get Offer</button></a>
          </ul>
        </div>
      </div>
      <div className='lg:invisible visible lg:hidden'>
        <MobileMenu />
      </div>

    </>
  );
};

export default Navbar;