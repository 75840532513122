import React from 'react'
import { BannerContainer } from '../components/BannerContainer'
import RotpayBanner from '../assets/images/rotpay_banner.png'
import Blocks from '../components/Blocks'
import RotpayImg from '../assets/images/homepage_rotpay.png'
import RotpayTransfer from '../assets/images/rotpay_transfer.png'

import LowCost from '../assets/icons/low_cost.svg'
import PaymentViaLink from '../assets/icons/payment_vialink.svg'
import PaymentViaPlugin from '../assets/icons/payment_viaplugin.svg'

import Visa from '../assets/images/homepage_visa.png'
import GPay from '../assets/images/homepage_gpay.png'
import Stripe from '../assets/images/homepage_stripe.png'
import ApplePay from '../assets/images/homepage_applePay.png'
import Revolut from '../assets/images/homepage_Revolut.png'
import Paypal from '../assets/images/homepage_paypal.png'
import Bank from '../assets/images/homepage_bank.png'
import Skrill from '../assets/images/homepage_skrill.png'

import RotpayWallet from '../assets/images/rotpay_wallet.png'
import RotpayPayout from '../assets/images/rotpay_payout.png'

import BitcoinIco from "../assets/icons/green-btc.png"
import RotpaySecurity from "../assets/icons/rotpay_security.svg"
import RotpayAccessebility from "../assets/icons/rotpay_accessebility.svg"
import RotpayLowCost from "../assets/icons/rotpay_lowCost.svg"
import RotpayUsability from "../assets/icons/rotpay_mobileUsability.svg"
import RotpayPrivacy from "../assets/icons/rotpay_privacy.svg"

import RotpayPaymnet from '../assets/images/rotpay_payment.png'


const Rotpay = () => {

    const options = {
        image: RotpayBanner,
        container: {
            width: 'lg:w-1/2 w-full',
            padding: 'lg:h-[500px]',
            marginY: 'my-12',
            margin: 'lg:ml-36'
        },
        header: {
            text: 'Your Payments Faster with Cryptocurrencies',
            size: 'lg:text-[48px] text-[40px] font-[NexaBold]',
            align: 'lg:text-left text-center max-w-[700px]',
            color: 'text-rot3-deep-green'
        },
        subHeader: {
            text: "RotPay enables everyone to securely and easily make and receive payments with cryptocurrencies.",
            size: 'heading9',
            align: 'lg:text-left text-center max-w-[700px]',
            bold: 'text-bold',
            margin: 'mb-10',
            color: 'text-rot3-deep-green'
        },
       
    }


    const blocksData = [
        { icon: BitcoinIco, title: "Quick Crypto Purchase" },
        { icon: RotpaySecurity, title: "Security" },
        { icon: RotpayAccessebility, title: "Global Accessebility" },
        { icon: RotpayLowCost, title: "Low Costs" },
        { icon: RotpayUsability, title: "Mobile Usability" },
        { icon: RotpayPrivacy, title: "Privacy" },
    ];



    const problemsSolutions = [
        { problem: "High commission and maintenance fees", solution: "Low transaction fees" },
        { problem: "Lower security against cyber-attacks", solution: "High security" },
        { problem: "Low transparency", solution: "Decentralization and transparency" },
        { problem: "Privacy infringements", solution: "High privacy" },
        { problem: "Service interruptions", solution: "Seamless service with Blockchain technology" },
        { problem: "International barriers", solution: "Smooth global-scale payments" },
        { problem: "Low speed in payments and cash access", solution: "High speed in payments and cash access" },
    ];

    return (

        <>
            <BannerContainer options={options} />
            <div className='bg-rot3-snow py-16'>
                <div className='lg:text-[48px] text-[40px] font-[NexaBold] text-rot3-deep-green text-center'>
                    <h3 className=' text-rot3-deep-green'>
                        Advantages of Cryptocurrency Payments
                    </h3>
                    <p className='heading9  lg:w-3/4 w-11/12 mx-auto'>There are many advantages for customers and businesses in making and accepting payments with RotPay.</p>
                </div>
                <Blocks blocks={blocksData} />

            </div>
            <div className='py-16'>
                <div className='text-center text-rot3-deep-green'>
                    <h3 className='heading4 '>WHY ROTPAY?</h3>
                    <p className='heading9  lg:w-3/4 w-11/12 mx-auto'>
                        RotPay's state-of-the-art payment processor can be easily integrated into websites and other
                        e-commerce applications, enabling instant payments in cryptocurrencies. RotPay facilitates payment transactions without the need
                        for an external blockchain wallet.
                    </p>
                </div>
            </div>
            <div>
                <div className='xl:flex lg:items-start items-center gap-[94px] justify-center p-3 py-16'>
                    <div className='text-rot3-deep-green'>
                        <div className='max-w-[700px] grid gap-10 xl:mx-0 mx-auto xl:pl-[117px] mb-10 lg:text-left text-center'>

                            <h3 className='heading4'>
                                RotPay’s Mission
                            </h3>
                            <p className='body1'>
                                RotPay aims to bring together businesses and individuals with Blockchain technology to provide them with a faster,
                                more transparent, and easier payment experience. Its core mission is to migrate the increasing digital payment methods to
                                Blockchain and merge them with the power of cryptocurrencies. Additionally, even users without any cryptocurrency wallet or
                                holdings can purchase cryptocurrencies via integrated payment methods such as Apple Pay, Google Pay, Stripe, Skrill, PayPal,
                                credit/debit cards, and bank transfers on the platform, enabling quick and highly convenient crypto payments to users and
                                customers who have never encountered cryptocurrencies before.
                            </p>
                        </div>
                    </div>

                    <div>
                        <img src={RotpayImg} alt="Rotpay" className='mx-auto' />
                    </div>
                </div>
            </div>
            <div>
                <div className='bg-rot3-deep-green py-16 px-20'>

                    <div className='grid lg:grid-cols-3 gap-20 items-center'>
                        <div>
                            <p className='text-white max-w-[350px] body1'>
                                For making payments, there's no need to create an account. Users can complete their payments by scanning a
                                QR code or copying a cryptocurrency wallet address. For e-commerce sites, selecting the "Pay with ROTPAY" option is sufficient.
                                Moreover, if users do not currently possess a cryptocurrency wallet or cryptocurrency assets, they can instantly purchase
                                cryptocurrencies through Rotpay and proceed with their transactions.
                            </p>
                        </div>
                        <div>
                            <img src={RotpayTransfer} alt="Rotpay" className='mx-auto' />
                        </div>
                        <div className='grid grid-rows-3 gap-20 items-center text-white'>
                            <div className="flex gap-4 items-center">
                                <img src={LowCost} alt="" />
                                <div>
                                    <h4 className='body2'>Payment via Plugin</h4>
                                    <p className='body3'>Integrate Rotpay plugin codes into your websites and mobile applications.</p>
                                </div>
                            </div>
                            <div className="flex gap-4 items-center">

                                <img src={PaymentViaLink} alt="" />
                                <div>
                                    <h4 className='body2'>Payment via Link</h4>
                                    <p className='body3'>Create payments and share the links.</p>
                                </div>
                            </div>
                            <div className="flex gap-4 items-center">

                                <img src={PaymentViaPlugin} alt="" />
                                <div>
                                    <h4 className='body2'>Low Costs</h4>
                                    <p className='body3'>We offer lower fees than any other method for your transfers.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='py-20'>
                <div className='text-rot3-deep-green grid lg:grid-cols-2 items-center lg:px-20 lg:gap-48 gap-10 text-center'>
                    <div className='max-w-[570px] mx-auto'>
                        <h3 className='heading2 mb-5 lg:text-left'>Buy Crypto Fast</h3>
                        <p className='body1 lg:text-left'>
                            One of the most significant advantages that sets
                            RotPay apart from other crypto payment platforms
                            is the ability for your customers to purchase
                            cryptocurrency using integrated methods such as
                            Google Pay, Apple Pay, Stripe, Skrill, Revolut Pay,
                            bank/credit cards, and instant bank transfers,
                            even if they don't own any cryptocurrency. After a
                            simple and quick identity verification process,
                            customers can swiftly make crypto payments.
                            <br /> <br />
                            Customers or users can transfer cryptocurrency to
                            you through Rotpay wallets without owning a
                            cryptocurrency wallet themselves.
                        </p>
                    </div>
                    <div className='grid lg:grid-cols-2 lg:gap-5 gap-10'>
                        <div className="grid grid-rows-4 grid-flow-col gap-4 mx-auto">
                            <img src={Visa} alt="Visa" />
                            <img src={GPay} alt="GPay" />
                            <img src={Stripe} alt="Stripe" />
                            <img src={ApplePay} alt="ApplePay" />
                            <img src={Revolut} alt="Revolut" />
                            <img src={Paypal} alt="Paypal" />
                            <img src={Bank} alt="Bank" />
                            <img src={Skrill} alt="Skrill" />
                        </div>
                        <div>
                            <img src={RotpayWallet} alt="Rotpay" className='mx-auto' />
                        </div>
                    </div>
                </div>
            </div >
            <div>

                <div className="container lg:px-20 mx-auto p-4 ">
                    <h3 className='heading5 text-rot3-deep-green text-center mb-10'>Industry Challenges & Solutions for Crypto Payments</h3>
                    <div className="border border-gray-200 rounded-[10px] overflow-hidden text-black  drop-shadow-xl">
                        <div className="grid grid-cols-2">
                            <div className="bg-white p-4 font-bold text-xl">Problems</div>
                            <div className="bg-white p-4 font-bold text-xl border-l border-gray-200">Solutions</div>
                        </div>
                        {problemsSolutions.map((item, index) => (
                            <div key={index} className={`grid grid-cols-2 ${index % 2 === 0 ? 'bg-[#F8F8F8]' : 'bg-white'}`}>
                                <div className="p-4">{item.problem}</div>
                                <div className="p-4 border-l border-gray-200">{item.solution}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className='py-16 lg:px-20 grid lg:grid-cols-2 items-center gap-10'>
                    <div>
                        <img src={RotpayPayout} alt="Rotpay_payout" className='lg:mx-0 mx-auto' />
                    </div>
                    <div className='text-rot3-deep-green lg:text-left text-center'>
                        <h5 className='body2 text-rot3-malachite'>Payment via Link</h5>
                        <h3 className='heading5 mb-5'>
                            INTEGRATION SOLUTIONS FOR EVERY SITUATION AND PLATFORM
                        </h3>
                        <p className='body1'>
                            Rotpay users can create personalized
                            cryptocurrency payments for individuals or
                            institutions and share them via SMS/email. In
                            created payment links, users can specify the
                            cryptocurrency unit, sending network, and
                            amount they wish to receive. After determining
                            the payment details, sharing the obtained
                            payment link with the customer allows them to
                            complete the payment via QR code and wallet
                            address. When the payment is transferred to the
                            Rotpay wallet, the recipient is notified via email
                            and SMS, completing the created payment.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div className='py-16 lg:px-20 grid lg:grid-cols-2 items-center gap-10'>

                    <div className='text-rot3-deep-green lg:text-left text-center'>
                        <h5 className='body2 text-rot3-malachite'>Payment via Plugin</h5>
                        <h3 className='heading5 mb-5'>
                            INTEGRATION SOLUTIONS FOR EVERY SITUATION AND PLATFORM
                        </h3>
                        <p className='body1'>
                            Businesses and individual users can integrate
                            RotPay's APIs into their e-commerce websites
                            or mobile applications and immediately start
                            receiving cryptocurrency payments. Simply
                            adding their RotPay account API key to their
                            websites and applications is sufficient to
                            begin.
                        </p>
                    </div>
                    <div>
                        <img src={RotpayPaymnet} alt="Rotpay_payout" className='mx-auto' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rotpay