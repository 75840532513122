import React from 'react'
import { BannerContainer } from '../components/BannerContainer';
import TextAreaWithIcon from '../components/TextAreaWithIcon'

import BlockchainBanner from '../assets/images/blockchain-banner.png'
import BlockchainBanner2 from '../assets/images/blockchain-banner2.png'
import BlockchainBanner3 from '../assets/images/blockchain-banner3.png'

import Image1 from '../assets/images/blockchain-image1.png'

import BlockIcon from '../assets/icons/blocks-icon.png'
import VirusIcon from '../assets/icons/virus-icon.png'
import PtopIcon from '../assets/icons/ptop-icon.png'
import IdeaIcon from '../assets/icons/idea-icon.png'
import ProcessorIcon from '../assets/icons/processor-icon.png'
import BinaryIcon from '../assets/icons/binary-icon.png'

import BitcoinIcon from '../assets/icons/Bitcoin.png'
import ExchangeIcon from '../assets/icons/Exchange.png'
import ConfidentalIcon from '../assets/icons/Confidential.png'
import SuitcaseIcon from '../assets/icons/Suitcase.png'
import CryptoIcon from '../assets/icons/Cryptocurrency.png'
import WalletIcon from '../assets/icons/Wallet.png'

import BlackIcon1 from '../assets/icons/black1.svg'
import BlackIcon2 from '../assets/icons/black2.svg'
import BlackIcon3 from '../assets/icons/black3.svg'
import BlackIcon4 from '../assets/icons/black4.svg'
import BlackIcon5 from '../assets/icons/black5.svg'
import BlackIcon6 from '../assets/icons/black6.svg'
import BlackIcon7 from '../assets/icons/black7.svg'
import BlackIcon8 from '../assets/icons/black8.svg'
import BlackIcon9 from '../assets/icons/black9.svg'

import GreenIco1 from '../assets/icons/green-icon1.png'
import GreenIco2 from '../assets/icons/green-icon2.png'
import GreenIco3 from '../assets/icons/green-icon3.png'
import GreenIco4 from '../assets/icons/green-icon4.png'
import GreenIco5 from '../assets/icons/green-icon5.png'
import GreenIco6 from '../assets/icons/green-icon6.png'

import BlackIcon21 from '../assets/icons/black-icon-12.png'
import BlackIcon22 from '../assets/icons/black2.svg'
import BlackIcon23 from '../assets/icons/black3.svg'
import BlackIcon24 from '../assets/icons/black-icon-10.png'
import BlackIcon25 from '../assets/icons/black-icon-11.png'
import BlackIcon26 from '../assets/icons/black6.svg'

const options = {
    image: BlockchainBanner,
    container: {
        width: 'lg:w-1/2 w-full',
        padding: 'lg:h-[500px]',
        marginY: 'my-16',
        margin: 'lg:ml-36'
    },
    header: {
        text: 'Empowering Enterprises With Smart Contracts',
        size: 'heading4 leading-tight',
        align: 'lg:text-left text-center max-w-[500px]',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: 'We automate industries and enterprises inside the blockchain ecosystem by implementing bug-free smart contracts that bind efficiency, transparency, and security for any business transaction over 70+ use cases including, DeFi, NFT, Gaming, Supply Chain, MLM, etc.',
        size: 'body1',
        align: 'lg:text-left text-center max-w-[600px]',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
    button: {
        padding: "lg:float-left",
        bordercolor: "bg-rot3-deep-green text-rot3-malachite hover:bg-[#0A5F5F]",
        textcolor: "text-rot3-deep-green",
        hover: "hover:text-rot3-malachite",
        text: "Contact Us",
    }
}

const options2 = {
    image: BlockchainBanner2,
    container: {
        width: 'lg:w-1/2 w-full',
        padding: 'lg:h-[500px]',
        marginY: 'my-16',
        margin: 'lg:ml-36'
    },
    header: {
        text: 'Ethereum Token Development Company',
        size: 'heading4 leading-tight',
        align: 'lg:text-left text-center max-w-[500px]',
        color: 'text-rot3-malachite'
    },
    subHeader: {
        text: 'We tokenize your physical assets, documents, collaterals, or anything into a non-breakable, secured, and easy-to-transfer crypto token on top of the Ethereum blockchain.',
        size: 'body1',
        align: 'lg:text-left text-center max-w-[600px]',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
    button: {
        padding: "lg:float-left",
        bordercolor: "bg-rot3-deep-green text-rot3-malachite hover:bg-[#0A5F5F]",
        textcolor: "text-rot3-deep-green",
        hover: "hover:text-rot3-malachite",
        text: "Contact Us",
    }
}

const options3 = {
    image: BlockchainBanner3,
    container: {
        width: 'lg:w-1/2 w-full',
        padding: 'lg:h-[700px]',
        marginY: 'my-36',
        margin: 'lg:ml-36'
    },
    header: {
        text: 'Build Decentralized Applications & Bring More Transparency',
        size: 'heading4 leading-tight',
        align: 'lg:text-left text-center max-w-[500px]',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: 'Enhance trust of your end-users by building decentralized applications which offers ultra transparency and security',
        size: 'body1',
        align: 'lg:text-left text-center max-w-[600px]',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
    button: {
        padding: "lg:float-left",
        bordercolor: "bg-rot3-deep-green text-rot3-malachite hover:bg-[#0A5F5F]",
        textcolor: "text-rot3-deep-green",
        hover: "hover:text-rot3-malachite",
        text: "Contact Us",
    }
}

const textAreaOptions = [
    {
        image: BlockIcon,
        header: "2 Architecture & Prototyping",
        description: "Our experts undergo crystal clear architecture design, and freezing the technical requirements, complete workflow with proper documentation.",
    },
    {
        image: VirusIcon,
        header: "Auditing & Bug Reporting",
        description: "Our smart contract experts Audit & test the complete workflow, as well as the internal functionalities and reporting the filtered bugs for reinforcement process",
    },
    {
        image: PtopIcon,
        header: "Smart Contracts Optimization",
        description: "We offer a smart contract optimization process to minimize the gas price, improving token performance, quicker invoice creation, secured private key management and more.",
    },
    {
        image: IdeaIcon,
        header: "Designing & Beta Development",
        description: "We empower the smart contract implementation with advanced technical internal designing and then moving it to the development with proper testing.",
    },
    {
        image: ProcessorIcon,
        header: "Contract Re-Engineering Services",
        description: "We empower the smart contract implementation with advanced technical internal designing and then moving it to the development with proper testing",
    },
    {
        image: BinaryIcon,
        header: "Smart Contract Deployment & Upgradation",
        description: "We finally Deploy the Beta smart contracts into the main network after several A/B Testing, and upgrading the working functionalities without any vulnerabilities.",
    },
]

const textAreaOptions2 = [
    {
        image: BitcoinIcon,
        header: "Token Creation",
        description: "Optimized outclass creation of fungible and non-fungible ERC 20 tokens within the ethereum blockchain that are more sustainable and can be recycled.",
    },
    {
        image: ExchangeIcon,
        header: "Token Migration",
        description: "Migrate your token from betanet to mainnet of any blockchain, and convert ERC20 token into the reliable token standard.",
    },
    {
        image: ConfidentalIcon,
        header: "Cold Storage",
        description: "We develop the best cold storage medium, hardware security responsible for token security and any compromise.",
    },
    {
        image: SuitcaseIcon,
        header: "Smart Contract Development",
        description: "Tokens without smart contracts are not possible, we create a separate smart contract for your token on ethereum blockchain and customize it based on preferences.",
    },
    {
        image: CryptoIcon,
        header: "Token Listing",
        description: "Our Experts supports you to list the created tokens on various top crypto exchanges to make it popular and to grab investors attention.",
    },
    {
        image: WalletIcon,
        header: "Token Wallet Development",
        description: "Creating customized crypto wallets separately to store and manage ethereum tokens which are created under any ethereum standards.",
    },
]

const textAreaOptionsB = [
    {
        image: BlackIcon1,
        header: "Smart Contract Development For MLM",
        description: "We specialize in creating and deploying error-free smart contracts for the development of decentralized smart contract MLM platforms on various blockchains, such as TRON and Ethereum. These MLM platforms are powered by blockchain smart contracts, making them fully decentralized, reducing risks, increasing trust, and eliminating the need for middlemen in MLM DApps.",
    },
    {
        image: BlackIcon2,
        header: "Smart Contract Development For NFT",
        description: "We develop and deploy error-free smart contracts to launch your own NFT marketplace or NFT tokens on any blockchain network, such as Solana, Binance Smart Chain, TRON, Ethereum, and more. NFT marketplace development and NFT token development rely on developing smart contracts to carry out operations. At Rot3, we specialize in developing, deploying, and auditing smart contracts to eliminate vulnerabilities.",
    },
    {
        image: BlackIcon3,
        header: "Smart Contract Development For DeFi",
        description: "Our developers specialize in creating smart contracts to accelerate the fast-growing Decentralized Finance (DeFi) industry. We develop blockchain smart contracts to build your own DeFi DApps with high-end security, scalability, and reliability, incorporating technical as well as user-attractive features and plugins.",
    },
    {
        image: BlackIcon4,
        header: "Smart Contract For Crypto Exchanges",
        description: "Our team creates smart contracts that track, execute, and manage assets during cryptocurrency transactions between buyers and sellers on decentralized cryptocurrency exchange (DEX) platforms. These smart contracts act as automated systems for order matching and asset holding in the crypto wallet of the DEX platform, streamlining the transaction process and ensuring transparency and security.",
    },
    {
        image: BlackIcon5,
        header: "Smart Contract For DApps",
        description: "Smart Contract Development for decentralized applications is one of our smart contract solutions. As dapps are a unique kind of blockchain apps, which will proceed with a unique kind of automation procedure based on the dapp nature. We are experts in creating smart contracts for dapp games that underlies any kind of blockchain network.",
    },
    {
        image: BlackIcon6,
        header: "Smart Contracts For Crowdsales",
        description: "We offer tailored smart contract development services specifically for blockchain crowdfunding programs like ICOs, STOs, ETOs, and IEOs. Our smart contracts are customized to ensure high security and traceability during the token issuance, agreements, and claiming process. With our top-notch smart contract development service, we can help make your crowd sale a success.",
    },
    {
        image: BlackIcon7,
        header: "Smart Contract For Businesses",
        description: "The potential of blockchain technology is not limited to cryptocurrency applications; it has the capability to transform various industries. We have the expertise to create customized smart contracts for any industry where blockchain is a priority. Our smart contract solutions cater to various industries such as healthcare, supply chain, real estate, automotive, insurance, and finance. We develop smart contracts to empower the entire ecosystem of these industries.",
    },
    {
        image: BlackIcon8,
        header: "Smart Contract For Metaverse",
        description: "To put it differently, our smart contract development services for Metaverse enable you to monitor and control the transactions and exchanges occurring on your virtual platform, which mimics the real world. By leveraging our expertise, we can design and implement high-performing smart contracts that automate your entire Metaverse system, ensuring seamless and secure operations.",
    },
    {
        image: BlackIcon9,
        header: "Smart Contract For DAO",
        description: "Rot3 offers a smart contract development service for DAO platforms. DAOs are completely decentralized organizations that operate with no central authority, and smart contracts set the foundational rules. Rot3's smart contract development service ensures that the DAO platform is autonomous and transparent. Smart contracts act as the fundamental element of the DAO platform.",
    },
]

const textAreaOptions3 = [
    {
        image: GreenIco1,
        header: "Custom DApp Development",
        description: "Our services involve developing decentralized applications (dapps) for any industry sector, using any type of blockchain network, and ensuring complete client satisfaction."
    },
    {
        image: GreenIco2,
        header: "DApp Designing",
        description: "Our team of dapp developers is extremely proficient and knowledgeable in creating dapps that are both highly secure and accurate in their design."
    },
    {
        image: GreenIco3,
        header: "Dapp Integration",
        description: "We analyze the specific needs and requirements of our clients' businesses and integrate the necessary functionalities into the dapps accordingly."
    },
    {
        image: GreenIco4,
        header: "Dapp Testing",
        description: "Our developers test the dapps we create for your business to ensure they operate seamlessly on both Android and iOS platforms."
    },
    {
        image: GreenIco5,
        header: "Dapp Porting",
        description: "At Rot3, we provide support for Dapp porting on any operating device, using reusable code bases and hassle-free platforms."
    },
    {
        image: GreenIco6,
        header: "Dapp Update Services",
        description: "To ensure the proper functioning of dapps, we offer update services for online businesses that are tailored to their specific requirements."
    }
]

const textAreaOptionsB2 = [
    {
        image: BlackIcon24,
        header: "Smart Contract Development For MLM",
        description: "We develop and implement error-free smart contracts to create decentralized MLM platforms on various blockchains, including Solana, TRON, and Ethereum. These platforms, powered by blockchain smart contracts, will be fully decentralized, reducing risks, increasing trust, and eliminating the need for intermediaries within the MLM DApp."
    },
    {
        image: BlackIcon22,
        header: "Smart Contract Development For NFT",
        description: "We specialize in developing and deploying bug-free smart contracts to launch your own NFT Marketplace or NFT tokens on any blockchain network, such as Solana, Binance Smart Chain, TRON, Ethereum, and more. Since the development of NFT Marketplaces and NFT tokens requires the use of smart contracts, we at Bitdeal ensure the elimination of vulnerabilities by developing, deploying, and auditing smart contracts."
    },
    {
        image: BlackIcon23,
        header: "Smart Contract Development For DeFi",
        description: "Our developers specialize in creating smart contracts that accelerate the rapidly growing Decentralized Finance (DeFi) industry. We develop blockchain smart contracts to build your own DeFi Dapps, ensuring high-end security, scalability, and reliability, as well as incorporating technical features and user-attractive plugins to enhance the overall experience."
    },
    {
        image: BlackIcon25,
        header: "Smart Contract For Crypto Exchanges",
        description: "We create smart contracts that track, execute, and manage assets during cryptocurrency transactions between buyers and sellers on a decentralized cryptocurrency exchange platform. These smart contracts act as an automated system for order matching and asset holding within the crypto wallet of the DEX platform."
    },
    {
        image: BlackIcon21,
        header: "Smart Contract For DApps",
        description: "We offer smart contract development solutions for decentralized applications, including dapps that require a unique type of automation procedure based on their nature. Our team of experts specializes in creating smart contracts for dapp games on any type of blockchain network."
    },
    {
        image: BlackIcon26,
        header: "Smart Contracts For Crowdsales",
        description: "We provide customized smart contract development services specifically for ICO, STO, ETO, IEO, and any other type of blockchain-based crowd sale programs. The smart contracts we create ensure high security and traceability for token issuance, agreements, and claiming processes. Our enhanced smart contract development services can help make your crowd sale a success."
    },
]

export const Blockchain = () => {
  return (
    <>
        <BannerContainer options={options} />

        <div>
            <div className='grid lg:grid-cols-2 py-16 items-center lg:text-left text-center'>
                <div>
                    <img className='mx-auto' src={Image1} alt="" />
                </div>
                <div className='max-w-[600px]'>
                    <p className='text-rot3-malachite heading4'>Smart Contract Development Company</p>
                    <p className='text-rot3-deep-green body1'>By realizing the importance of blockchain technology and its smart contracts, Bitdeal turned itself into a Full Stack Smart Contract Development Company by creating smart contracts with zero bugs for various industrial use-cases Like DeFi, NFT, Supply chain, Dapp, Exchange, Gaming, Banking, and Other Enterprise use cases. We have a pool of smart contract developers who can build smart contracts with different functionalities on different blockchain networks Like Ethereum, TRON, EOS, Tezos, Binance Smart chain, Hyperledger and so on. Join us to automate your business and bring revolution through blockchain smart contracts.</p>
                </div>
            </div>
        </div>

        <div>
            <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green text-center lg:px-20'>Smart Contract Development Services</p>
            <div>
                <div className="container-max max-w-[1440px] mx-auto py-4 lg:px-20">
                    <div className="grid lg:grid-cols-3 justify-items-center gap-10">
                        {textAreaOptions.map( (item) => {
                            return (
                            <div className='bg-rot3-snow rounded-lg p-6'>
                                <TextAreaWithIcon item={item} hclass={'heading8 text-center text-rot3-deep-green font-semibold text-xl py-4'} dclass={'mx-auto text-rot3-deep-green body1 text-center'} iclass={'mx-auto'}/>
                            </div>
                            )
                        } ) }
                    </div>
                </div>
            </div>
        </div>

        <BannerContainer options={options2} />

        <div className='py-16 lg:px-20'>
            <p className='heading4 text-rot3-deep-green text-center'>Ethereum Token Development Services</p>
            <div>
                <div className="container-max max-w-[1440px] mx-auto py-4">
                    <div className="grid lg:grid-cols-3 justify-items-center gap-10">
                        {textAreaOptions2.map( (item) => {
                            return (
                                <TextAreaWithIcon item={item} hclass={'heading8 text-center text-rot3-malachite font-semibold text-xl py-4'} dclass={'mx-auto text-rot3-deep-green body1 text-center'} iclass={'mx-auto'}/>
                            )
                        } ) }
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div className='bg-rot3-deep-green py-8 lg:px-20'>
                <p className='heading4 max-w-[800px] mx-auto leading-tight text-rot3-malachite text-center'>Our Smart Contract Development Solutions</p>
                <div className="container-full lg:mx-20 mx-4">
                    <div className="container-max mx-auto py-4">
                        <div className="grid lg:grid-cols-3 justify-items-center gap-16">
                            {textAreaOptionsB.map((item) => {
                                return <TextAreaWithIcon item={item} hclass={'heading8 text-rot3-malachite text-center lg:text-left'} dclass={'mx-auto body1 text-white lg:text-left text-center'} iclass={'lg:m-0 mx-auto'} iheight={'h-12'} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BannerContainer options={options3} />

        <div className='py-16 lg:px-20'>
            <p className='heading4 text-rot3-deep-green text-center pb-8'>Dapp Development Services</p>
            <p className='heading9 text-rot3-deep-green text-center'>We furnish dapp development services for various industries to uplift their business development & ROI.</p>
            <div className="container-full">
                <div className="container-max mx-auto py-24">
                    <div className="grid lg:grid-cols-3 justify-items-center gap-6">
                        { textAreaOptions3.map( (item) => {
                            return <TextAreaWithIcon item={item} hclass={'heading8 text-rot3-deep-green text-center lg:text-left'}  dclass={'body1 text-center lg:text-left text-[#8E9498]'} iclass={'lg:m-0 mx-auto'}/>
                        })}
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div className='bg-rot3-deep-green py-8 lg:px-20'>
                <p className='heading4 max-w-[800px] mx-auto leading-tight text-rot3-malachite text-center'>Our DApp Development Solutions</p>
                <p className='heading9 text-rot3-malachite text-center pb-6'>Here is the list of dapp development related solutions we provide across the overseas.</p>
                <div className="container-full lg:mx-20 mx-4">
                    <div className="container-max mx-auto py-4">
                        <div className="grid lg:grid-cols-3 justify-items-center gap-10">
                            {textAreaOptionsB2.map((item) => {
                                return <TextAreaWithIcon item={item} hclass={'heading8 text-rot3-malachite text-center lg:text-left'} dclass={'mx-auto body1 text-white lg:text-left text-center'} iclass={'lg:m-0 mx-auto'} iheight={'h-12'} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
