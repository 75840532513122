import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Slider1 from '../assets/images/slider-1.png'
import Slider2 from '../assets/images/slider-2.png'
import Slider3 from '../assets/images/slider-3.png'
import Slider4 from '../assets/images/slider-4.png'
import { BannerContainer } from './BannerContainer';

const options = {
    image: Slider1,
    container: {
        width: 'lg:w-1/3 w-full',
        padding: 'lg:h-[500px]',
        marginY: 'my-20',
        margin: 'lg:ml-36'
    },
    header: {
        text: 'Real-Time and Historical Financial Market Data',
        size: 'sm:heading2 heading5',
        align: 'lg:text-left text-center',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: 'Stocks, Forex, Cryptocurrency, Indices, ETFs via API and WebSocket Start Free Trial',
        size: 'heading9',
        align: 'lg:text-left text-center',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
}

const options2 = {
    image: Slider2,
    container: {
        width: 'lg:w-1/3 w-full',
        padding: 'lg:h-[500px]',
        margin: 'lg:ml-48 mx-4',
        marginY: 'my-20',
    },
    header: {
        text: 'NFT Trading Platform Solutions',
        size: 'sm:heading2 heading5',
        align: 'lg:text-left text-center',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: 'Rot3 offers technical infrastructure services that allow you to create a comprehensive NFT trading platform with various trading features.',
        size: 'heading9',
        align: 'lg:text-left text-center',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
}

const options3 = {
    image: Slider3,
    container: {
        width: 'lg:w-1/3 w-full',
        padding: 'lg:h-[500px]',
        margin: 'lg:ml-48 mx-4',
        marginY: 'my-20',
    },
    header: {
        text: 'Build Your Own Crypto Exchange',
        size: 'sm:heading2 heading5',
        align: 'lg:text-left text-center',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: 'Rot3 offers technical infrastructure services that allow you to create a comprehensive NFT trading platform with various trading features.',
        size: 'heading9',
        align: 'lg:text-left text-center',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
}

const options4 = {
    image: Slider4,
    container: {
        width: 'lg:w-1/3 w-full',
        padding: 'lg:h-[500px]',
        margin: 'lg:ml-48 mx-4',
        marginY: 'my-16',
    },
    header: {
        text: 'Business Benefits of Smart Contract Development',
        size: 'sm:heading2 heading5',
        align: 'lg:text-left text-center',
        color: 'text-rot3-malachite'
    },
    subHeader: {
        text: 'Smart contracts are simple programs stored on a blockchain that run when predetermined conditions are met.',
        size: 'heading9',
        align: 'lg:text-left text-center',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
}

export const ResponsiveCarousel = () => {
        return (
            <Carousel autoPlay={false} infiniteLoop={true} interval={5000} transitionTime={1000} showThumbs={false} >
                <BannerContainer options={options3}/>
                <BannerContainer options={options}/>
                <BannerContainer options={options2}/>
                <BannerContainer options={options4}/>
            </Carousel>
        );
};



