import React, { useState } from 'react'
import Logo from "../assets/images/rot3-logo.svg"

export const MobileMenu = () => {
    const [isActive, setIsActive] = useState(false);
    const [displayed, setDisplayed] = useState(null);
    const [subDisplayed, setSubDisplayed] = useState(null);

    const menuHandler = () => {
        if (isActive) {
            setIsActive(false)
        }

        if (!isActive) {
            setIsActive(true)
        }
    }

    const displayHandler = (x) => {
        if (displayed) {
            setDisplayed(null)
        }
        if (!displayed) {
            setDisplayed(x)
        }
    }

    const subDisplayHandler = (x) => {
        if (subDisplayed) {
            setSubDisplayed(null)
        }
        if (!subDisplayed) {
            setSubDisplayed(x)
        }
    }


    return (
        <>

            <nav className="fixed top-0 z-50 w-full bg-rot3-snow">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start p-2">
                            <a href="/"><img className='w-3/4' src={Logo} alt="" /></a>
                        </div>
                        <button data-drawer-target="logo-sidebar"
                            data-drawer-toggle="logo-sidebar"
                            aria-controls="logo-sidebar"
                            type="button"
                            className="inline-flex items-center p-2 text-sm bg-rot3-header-bg2 rounded-lg lg:hidden"
                            onClick={menuHandler}>
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
            {isActive &&

                <aside id="logo-sidebar" className="fixed top-0 right-0 z-40 w-64 h-screen pt-20 transition-transform border-2 bg-rot3-snow translate-x-0" aria-label="Sidebar">
                    <div className="h-full px-3 pb-4 overflow-y-auto bg-rot3-snow">
                        <ul className="space-y-2">
                            <li>
                                <a href="/" className="flex items-center p-2 text-base font-normal text-deep-green font-bold rounded-lg  ">
                                    <span className="ml-3">Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="/about" className="flex items-center p-2 text-base font-normal text-deep-green font-bold rounded-lg   ">

                                    <span className="flex-1 ml-3 whitespace-nowrap">About</span>

                                </a>
                            </li>
                            {/* <li>
                                <a href="/news" className="flex items-center p-2 text-base font-normal text-deep-green font-bold rounded-lg   ">
                                    
                                    <span className="flex-1 ml-3 whitespace-nowrap">News</span>
                                    
                                </a>
                            </li> */}

                            <li onClick={() => { setDisplayed('solutions'); displayHandler('solutions'); }}>
                                <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  ">

                                    <span className="flex-1 ml-3 whitespace-nowrap">Solutions▾</span>
                                </a>
                            </li>
                            {
                                displayed === "solutions" ?
                                    <div>
                                        <ul>
                                            <li onClick={() => { setSubDisplayed('bs'); subDisplayHandler('bs'); }} className='className="flex items-center p-2 pl-8 text-base font-normal text-gray-900'>Blockchain Solutions▾
                                                {
                                                    subDisplayed === "bs" ?
                                                        <div className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>
                                                            <ul>
                                                                <a href="/blockchain-solutions/solutions"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Cryptocurrency Exchange</li></a>
                                                                <a href="/blockchain-solutions/blockchain"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Blockchain</li></a>
                                                                <a href="/blockchain-solutions/nft-wallet-services"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white rounded-md'>NFT Wallet Services</li></a>
                                                            </ul>
                                                        </div>
                                                        : ""
                                                }
                                            </li>
                                            <li onClick={() => { setSubDisplayed('dp'); subDisplayHandler('dp'); }} className='className="flex items-center p-2 pl-8 text-base font-normal text-gray-900'>Data Provider▾
                                                {
                                                    subDisplayed === "dp" ?
                                                        <div className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>
                                                            <ul>
                                                                <a href="/data-provider"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Stock</li></a>
                                                                <a href="/data-provider"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Forex</li></a>
                                                                <a href="/data-provider"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Crypto</li></a>
                                                                <a href="/data-provider"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>Indexes</li></a>
                                                                <a href="/data-provider"><li className='bg-gradient-to-l from-rot3-malachite to-rot3-white mb-2 rounded-md'>EFTs</li></a>
                                                            </ul>
                                                        </div>
                                                        : ""
                                                }
                                            </li>

                                            <li>
                                                <a href="/rotpay" className="flex items-center p-2 pl-5 text-base font-normal text-gray-900 font-bold rounded-lg   ">

                                                    <span className="flex-1 ml-3 whitespace-nowrap">Rotpay</span>

                                                </a>
                                            </li>
                                            <li>
                                                <a href="/rot3wallet" className="flex items-center p-2 pl-5 text-base font-normal text-gray-900 font-bold rounded-lg   ">

                                                    <span className="flex-1 ml-3 whitespace-nowrap">Rot3 Wallet</span>

                                                </a>
                                            </li>


                                        </ul>
                                    </div>
                                    : ""
                            }

                            <li onClick={() => { setDisplayed('marketing'); displayHandler('marketing'); }}>
                                <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  ">

                                    <span className="flex-1 ml-3 whitespace-nowrap">Marketing▾</span>
                                </a>
                            </li>
                            {
                                displayed === "marketing" ?
                                    <div>
                                        <ul>
                                            <a href="https://fitechpro.com/"><li className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>Marketing Platform</li></a>
                                            {/* <a href="/marketing/marketing-platform"><li className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>E-Mail Marketing</li></a> */}
                                            <a href="https://fitechpro.com/gamification"><li className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>Gamification</li></a>
                                            <a href="/marketing/logo-website-design"><li className='flex items-center p-2 pl-8 text-base font-normal text-gray-900'>Logo - Website Design</li></a>
                                        </ul>
                                    </div>
                                    : ""
                            }
                            <li>
                                <a href="/careers" className="flex items-center p-2 text-base font-normal text-deep-green font-bold rounded-lg ">

                                    <span className="flex-1 ml-3 whitespace-nowrap">Careers</span>
                                </a>
                            </li>
                            <li>
                                <a href="/contact-us" className="flex items-center p-2 text-base font-normal text-deep-green font-bold rounded-lg">

                                    <span className="flex-1 ml-3 whitespace-nowrap">Contact Us</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>

            }

        </>

    )
}
