import React from "react";
import { Outlet } from "react-router-dom"
import Footer from './Footer';
import Navbar from './Navbar'

const LayoutWebsiteV2 = () => {
    return (
        <>
            <Navbar />
            <div className="m-auto" id='main' >
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default LayoutWebsiteV2;