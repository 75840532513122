const MenuContent = () => {
    return (
        [
            {   id: 0, 
                name: 'COMPANY',
                header: 'COMPANY',
                image: '', icon:'',
                description: '',
                status : true, link: '/', subItems : [
                    {   id: 3, 
                        name: '',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/', subItems: [
                            {   id: 4, 
                                name: 'About',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/about', subItems: [
                                    
                                ]
                            },
                            // {   id: 5, 
                            //     name: 'News',
                            //     image: '', icon:'', 
                            //     description: '',
                            //     status : true, link: '/news', subItems: [
                                    
                            //     ]
                            // },
                            {   id: 6, 
                                name: 'Careers',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/careers', subItems: [
                                    
                                ]
                            },
                            {   id: 7, 
                                name: 'Contact Us',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/contact-us', subItems: [
                                    
                                ]
                            }, 
                        ]
                    }
                ]
            },
                {   id: 1, 
                    name: 'BLOCKCHAIN SOLUTIONS',
                    header: 'BLOCKCHAIN SOLUTIONS',
                    image: '', icon:'',
                    description: '',
                    status : true, link: '/', subItems : [
                    {   id: 8, 
                        name: '',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/', subItems: [
                            {   id: 9, 
                                name: 'Cryptocurrency Exchange',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/blockchain-solutions/solutions', subItems: [
                                    
                                ]
                            },
                            {   id: 10, 
                                name: 'Blockchain',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/blockchain-solutions/blockchain', subItems: [
                                    
                                ]
                            },
                            {   id: 11, 
                                name: 'NFT Wallet Services',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/blockchain-solutions/nft-wallet-services', subItems: [
                                    
                                ]
                            },             
                        ]
                    }
                ]
            },
                {   id: 2, 
                    name: 'DATA PROVIDER',
                    header: 'DATA PROVIDER',
                    image: '', icon:'',
                    description: '',
                    status : true, link: '/', subItems : [
                    {   id: 13, 
                        name: '',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/', subItems: [
                            {   id: 14, 
                                name: 'Stocks',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/data-provider', subItems: [
                                    
                                ]
                            },
                            {   id: 15, 
                                name: 'Forex',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/data-provider', subItems: [
                                    
                                ]
                            },
                            {   id: 16, 
                                name: 'Crypto',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/data-provider', subItems: [
                                    
                                ]
                            },
                            {   id: 17, 
                                name: 'Indexes',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/data-provider', subItems: [
                                    
                                ]
                            },    
                            {   id: 18, 
                                name: "EFT's",
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/data-provider', subItems: [
                                    
                                ]
                            },          
                        ]
                    }
                ]},
                // {   id: 19, 
                //     name: 'FOREX SOLUTIONS',
                //     header: 'FOREX SOLUTIONS',
                //     image: '', icon:'',
                //     description: '',
                //     status : true, link: '/', subItems : [
                //     {   id: 20, 
                //         name: '',
                //         image: '', icon:'', 
                //         description: '',
                //         status : true, link: '/', subItems: [
                //             {   id: 21, 
                //                 name: 'Legal Solutions',
                //                 image: '', icon:'', 
                //                 description: '',
                //                 status : true, link: '/forex-solutions/forex-legal-solutions', subItems: [
                                    
                //                 ]
                //             },
                //             {   id: 22, 
                //                 name: 'Technology Solutions',
                //                 image: '', icon:'', 
                //                 description: '',
                //                 status : true, link: '/forex-solutions/forex-tech-solutions', subItems: [
                                    
                //                 ]
                //             },
                //             // {   id: 23, 
                //             //     name: 'Financial Services',
                //             //     image: '', icon:'', 
                //             //     description: '',
                //             //     status : true, link: '/data-provider', subItems: [
                                    
                //             //     ]
                //             // },       
                //         ]
                //     }
                // ]},
                {   id: 24, 
                    name: 'MARKETINGS',
                    header: 'MARKETINGS',
                    image: '', icon:'',
                    description: '',
                    status : true, link: '/', subItems : [
                    {   id: 25, 
                        name: '',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/', subItems: [
                            {   id: 26, 
                                name: 'Marketing Platform',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: 'https://fitechpro.com/', subItems: [
                                    
                                ]
                            },
                            // {   id: 27, 
                            //     name: 'E-mail Marketing',
                            //     image: '', icon:'', 
                            //     description: '',
                            //     status : true, link: '/marketing/marketing-platform', subItems: [
                                    
                            //     ]
                            // },
                            {   id: 28, 
                                name: 'Gamification',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: 'https://fitechpro.com/gamification', subItems: [
                                    
                                ]
                            },  
                            {   id: 29, 
                                name: 'Logo&Web Design',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/marketing/logo-website-design', subItems: [
                                    
                                ]
                            },        
                        ]
                    }
                ]},
        ]
    )
}

export default MenuContent;