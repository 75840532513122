import React from 'react'

import { BannerContainer } from '../components/BannerContainer'
import Input from '../components/Input'

import CareersBanner from '../assets/images/careers-banner.png'
import lines from '../assets/images/lines.png'  

const options = {
  image: CareersBanner,
  container: {
      width: 'lg:w-1/2 w-full',
      padding: 'lg:h-[500px]',
      marginY: 'my-16',
      margin: 'lg:ml-28'
  },
  header: {
      text: 'Join the Revolution of Blockchain and Web3 Technology with ROT3',
      size: 'heading2',
      align: 'lg:text-left text-center max-w-[700px]',
      color: 'text-white'
  },
  subHeader: {
      text: "At ROT3, we believe that our team's happiness and wellbeing are crucial to our success. That's why we strive to create a supportive and inspiring working environment that fosters growth, creativity, and collaboration.",
      size: 'heading9',
      align: 'lg:text-left text-center max-w-[700px]',
      bold: 'text-bold',
      margin : 'mb-8',
      color: 'text-white'
  },
  button: {
      padding: "lg:float-left",
      bordercolor: "border-rot3-deep-green hover:border-rot3-malachite",
      textcolor: "text-rot3-deep-green",
      hover: "hover:text-rot3-malachite",
      text: "Contact Us",
  }
}

const nameInputProps = {
  id: "",
  label: "First name",
  width: "lg:w-11/12 w-full",
  height: "h-[48px]",
  placeholder: "Type your name",
}

const lastNameInputProps = {
  id: "",
  label: "Last name",
  width: "w-full",
  height: "h-[48px]",
  placeholder: "Type your last name",
}

const emailInputProps = {
  id: "",
  label: "Email",
  width: "lg:w-11/12 w-full",
  height: "h-[48px]",
  placeholder: "Type your email",
}

const numberInputProps = {
  id: "",
  label: "Number",
  width: "w-full",
  height: "h-[48px]",
  placeholder: "Type your phone number",
}

const areaInputProps = {
  id: "",
  label: "Message",
  width: "w-full",
  height: "h-[200px]",
  placeholder: "Please, type your message",
}

export const Careers = () => {
  return (
    <>
    <BannerContainer options={options} />
    <div className='grid lg:grid-cols-2 gap-4 text-rot3-deep-green items-center lg:px-20'>
      <div className=' lg:text-left text-center pt-8'>
        <p className='heading3'>
            Why ROT3?</p>
        <p className='py-4 heading9'>Join Rot3, a leading blockchain and web3 technology company with over 3 years of experience. We value diversity, collaboration, and innovation, offering remote work flexibility worldwide. Educational opportunities are provided for continuous growth. Seeking passionate individuals to join our dynamic team and contribute to the future of blockchain and web3 technology.</p>
      </div>
      <div className='flex mx-auto'>
          <img className=' mt-24 pb-28 w-1/8' src={lines} alt=""/>
          <div className='grid grid-rows-4 pb-8 mt-[106px] ml-2'>
              <p>Remote job and flexible working hours.</p>
              <p>Working with passionate and friendly people.</p>
              <p>Competitive salary and working in a fast-growing company.</p>
              <p>Reflect the diversity with equitable access to opportunity for everyone.</p>
          </div>
      </div>
    </div>

    <hr className='border-1 border-rot3-deep-green mx-auto mb-4' />

    <div className='text-rot3-deep-green py-8 lg:text-left text-center lg:px-20'>
      <p className='heading4 text-center'>Data Analyst Engineer</p>
      <p className='heading5 pt-8'>Responsibilities</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Ability to turn data into actionable insights using various big data analytic frameworks.</li>
        <li>Find patterns, anomalies, trends, etc. in large and complex data sets.</li>
        <li>Interpret data, analyze results using statistical techniques and develop interactive dashboards, reports, etc.</li>
        <li>Implement ETL pipelines regarding quality assurance of data and deploy them into production environments.</li>
        <li>Provide technical expertise on data storage structures, data cleansing and data visualization.</li>
        <li>Basic Qualifications</li>
        <li>Work experience in reporting, data analytics, or business intelligence</li>
        <li>Strong data management skills (data preparation, data quality management, etc.)</li>
        <li>Strong data analytical and problem-solving skills.</li>
        <li>Knowledge of statistical concepts and methods</li>
        <li>2+ years of hands-on experience with one or more programming languages: Python.</li>
        <li>SQL Knowledge is a must.</li>
      </ul>

      <p className='heading5 pt-8'>Preferred Qualifications</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Computing frameworks: Hive, Spark</li>
        <li>Knowledge of machine learning methods</li>
        <li>Knowledge of algorithms in anomaly detection, demand forecasting, optimization, etc.</li>
        <li>xperience with building interactive visualization and BI platforms (Python Bokeh Qliksense/Tableau/Power BI, Superset, etc.)</li>
        <li>Scrum</li>
      </ul>
    </div>

    <div className='text-rot3-deep-green py-8 lg:text-left text-center lg:px-20'>
      <p className='heading4 text-center'>Frontend Software Developer</p>
      <p className='heading5 pt-8'>Responsibilities</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Contribute to the design, prototype, development, and test phases for features across several JavaScript-based Website and Dashboard</li>
        <li>Work with and support members from other cross-functional teams on delivering on features from inception to production in an agile environment</li>
        <li>Ensuring JS standards are consistently upheld</li>
        <li>Become an active member in both JS and wider engineering communities.</li>
      </ul>

      <p className='heading5 pt-8'>Skills and Requirements:</p>
      <ul className='list-disc ml-4 text-left'>
        <li>In-depth knowledge of modern JavaScript (ES6+)</li>
        <li>Knowledge of modern web technologies/tooling (e.g. Angular 13+, Tailwind CSS, ….., etc.)</li>
        <li>Understanding of agile practices</li>
        <li>Understanding of UX best practices, including accessibility standards</li>
        <li>Knowledge of different browsers, cross-browser compatibility, and testing</li>
        <li>Understanding and experience designing for scalability, resiliency, and performance</li>
        <li>Knowledge of optimization and measuring the performance of JavaScript</li>
        <li>A commitment to learning, discovering new technology and sharing within the team</li>
      </ul>
    </div>

    <div className='text-rot3-deep-green py-8 lg:text-left text-center lg:px-20'>
      <p className='heading4 text-center'>Go Developer</p>
      <p className='heading5 pt-8'>You Will Be Responsible For</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Working in a multi-functional, self-organizing team</li>
        <li>Participating in the planning, design, implementation, and support of the products</li>
        <li>Following and contributing to the development of best practices, giving and receiving code reviews.</li>
        <li>Constantly improving as a developer and a team member, and sharing knowledge with other team members.</li>
      </ul>

      <p className='heading5 pt-8'>Ideal Profile</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Golang</li>
        <li>Strong knowledge of OOP</li>
        <li>MongoDB, Redis</li>
        <li>REST API</li>
        <li>User authentication and authorization between multiple systems, servers, and environments.</li>
      </ul>

      <p className='heading5 pt-8'>Whats on offer?</p>
      <ul className='list-disc ml-4 text-left'>
        <li>Work alongside & learn from best in class talent</li>
        <li>Excellent career development opportunities</li>
        <li>Great work environment.</li>
      </ul>
    </div>

    <div className='py-8 mx-[10%]'>
        <div className=' bg-white shadow-2xl rounded-md w-full pt-8'>
            <div className='grid lg:grid-cols-2 mx-[16%]'>
                <div>
                    <div className="py-3" ><Input props={nameInputProps}/></div>
                    <div className="py-3" ><Input props={emailInputProps}/></div>
                </div>
                <div>
                    <div className="py-3" ><Input props={lastNameInputProps}/></div>
                    <div className="py-3" ><Input props={numberInputProps}/></div>
                </div>
            </div>
            <div className='mx-[16%]'>
                <div className="py-3" ><Input props={areaInputProps}/></div>
                <div className="pt-4 pb-16 lg:text-left text-center" >
                  <button className='bg-rot3-deep-green text-rot3-malachite body2 py-2 px-4 rounded-lg hover:bg-[#0A5F5F]'>
                    Send
                  </button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
