import React from 'react'
import TextAreaWithIcon from '../components/TextAreaWithIcon'

// Images
import bannerImage from '../assets/images/data-provider-banner1.png'
import bannerImage2 from '../assets/images/data-provider-banner2.png'
import bannerImage3 from '../assets/images/data-provider-banner3.png'
import bannerImage4 from '../assets/images/data-provider-banner4.png'
import bannerImage5 from '../assets/images/data-provider-banner5.png'
import bannerImage6 from '../assets/images/data-provider-banner6.png'

import Image1 from '../assets/images/data-provider-image1.png'
import Image2 from '../assets/images/data-provider-image2.png'
import Image3 from '../assets/images/data-provider-image3.png'
import Image4 from '../assets/images/data-provider-image4.png'
import Image5 from '../assets/images/data-provider-image5.png'
import Image6 from '../assets/images/data-provider-image6.png'
import Image7 from '../assets/images/data-provider-image7.png'
import Image8 from '../assets/images/data-provider-image8.png'
import Image9 from '../assets/images/data-provider-image9.png'
import Image10 from '../assets/images/data-provider-image10.png'
import Image11 from '../assets/images/data-provider-image11.png'
import Image12 from '../assets/images/data-provider-image12.png'
import Image13 from '../assets/images/data-provider-image13.png'

// Icons

import Icon1 from '../assets/icons/realtime-icon.png'
import Icon2 from '../assets/icons/analitic-icon.png'
import Icon3 from '../assets/icons/fundamental-icon.png'
import GreenTick from '../assets/icons/tick-icon.svg'
import { BannerContainerWithCol } from '../components/BannerContainerWithCol'


export const DataProvider = () => {
  const options = {
    image: bannerImage,
    header: {
      text: 'US & Global Stock Data All In One Place',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center leading-[70px]'
    },
    subHeader: {
      text: "Our real-time streaming and historical API provides stock data that you can use to build charts, applications, and platforms with a single subscription.",
      class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
    },
    button: {
      text: "CONTACT US",
      class: "bg-rot3-deep-green text-rot3-malachite body2 py-2 px-4 rounded-lg lg:mx-0 mx-auto hover:bg-[#0A5F5F] leading-10"
  }
  }

  const options2 = {
    image: bannerImage2,
    header: {
      text: 'Forex Data with Global Coverage',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center pt-24'
    },
    subHeader: {
      text: "Real-time and Historical Foreign Exchange (FX) rates via WebSockets and APIs from over 200 world currencies.",
      class: "heading9 text-black lg:text-left text-center mb-4 py-4"
    }
  }

  const options3 = {
    image: bannerImage3,
    header: {
      text: 'Crypto Data With Major Exchanges',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center pt-24'
    },
    subHeader: {
      text: "Real-time and Historical crypto data via WebSockets and APIs from over 15 crypto exchanges.",
      class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
    },
    button: {
      text: "Contact Us",
      class: "bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 lg:mx-0 mx-auto rounded-lg hover:shadow-lg"
  }
  }

  const options4 = {
    image: bannerImage4,
    header: {
      text: 'Premium Global Market Indexes',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center pt-24'
    },
    subHeader: {
      text: "Rot3offers Real-time and historical data for index value for more than 1,600 global market including market leading benchmarks.",
      class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
    },
    button: {
      text: "Contact Us",
      class: "bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 lg:mx-0 mx-auto rounded-lg hover:shadow-lg"
  }
  }

  const options5 = {
    image: bannerImage5,
    header: {
      text: 'Global ETF and Mutual Funds Prices',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center pt-24'
    },
    subHeader: {
      text: "Rot3provides real-time, delayed, end-of-day and historical pricing data for exchange traded funds (ETFs) and mutual funds data.",
      class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
    },
    button: {
      text: "Contact Us",
      class: "bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 lg:mx-0 mx-auto rounded-lg hover:shadow-lg"
  }
  }

  const options6 = {
    image: bannerImage6,
    header: {
      text: 'Fundamental Data with All Financial Assets',
      class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center pt-24'
    },
    subHeader: {
      text: "Access stocks, forex, crypto – news, economic calendar, statements, status and more.",
      class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
    },
    button: {
      text: "Contact Us",
      class: "bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 lg:mx-0 mx-auto rounded-lg hover:shadow-lg"
  }
  }

  const textAreaOptions = [
    {
        image: Icon1,
        header: "Real-Time",
        description: "Receive live streaming stock market data with real-time updates within milliseconds and improve your application's functionality using Rot3's API and WebSocket.",
    },
    {
        image: Icon2,
        header: "Historical Data",
        description: "This service provides historical stock data API for all stock exchanges for the past 18 years. The data includes last price, OHLCV (open, high, low, close, volume), aggregate, LV1 and LV2 market depth data.",
    },
    {
        image: Icon3,
        header: "Fundamentals",
        description: "Rot3's Financial Fundamentals data offers a stock API that can enhance your website with features such as stock quote charts, stock news, market status, and company information.",
    },
]

  return (
    <>

    {/* STOCK */}

      <BannerContainerWithCol options={options} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>
      <div className='text-center pb-8'>
        <p className='body4'>STOCK</p>
        <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Market Data</p>
        <p className='heading9'>With Various Options</p>
      </div>
      <div className="max-w-[1440px] lg:px-20">
              <div className="container-max mx-auto py-4">
                  <div className="grid lg:grid-cols-3 justify-items-center gap-10">
                      {textAreaOptions.map( (item) => {
                          return <TextAreaWithIcon item={item} hclass={'heading8 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green text-center py-4'} dclass={'body1 mx-auto text-center'} iclass={'mx-auto'}/>
                      } ) }
                  </div>
              </div>
      </div>

      <div className='lg:text-left text-center pt-8 lg:px-20'>
        <div className='grid grid-rows-2'>
          <div className='grid lg:grid-cols-2 py-16'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Stock Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 provides high-performance and low-latency solutions for your product by offering access to over 25,000 US stock data and other global markets.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Stock Market Details</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Tick data</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Stock Market Details</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <img src={Image1} className="lg:float-right lg:mx-0 mx-auto pt-8" alt="" />
            </div>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              <img src={Image2} className="pt-8 lg:mx-0 mx-auto" alt="" />
            </div>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Stock Market Real-Time WebSocket</p>
              <p className='bold heading5'>Institutional Level Connection</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 provides different types of servers for your applications, including public server, virtual private server (VPS), and dedicated server. You can edit, manage, and build your plans on your private server, and change the incoming data structure in just a few minutes.</p>
              <div className='grid lg:grid-cols-2 body2 pb-20'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Stock Market Details</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Tick data</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical End of Day Data</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Stock Market Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='max-w-[1440px] mx-auto'/>
      {/* FOREX */}

      <BannerContainerWithCol options={options2} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>

      <div className='lg:text-left text-center pt-8 lg:px-20'>
        <div className='grid grid-rows-2'>
          <div className='grid lg:grid-cols-2'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Forex Data Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3offers low latency and the best performance for your product with more than 2000+ forex, commodities, bonds and metal symbols.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Forex Details</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Tick data</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical End of Day Data</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Forex Aggregates</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Forex Status</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <img src={Image3} className="lg:float-right lg:mx-0 mx-auto" alt="" />
            </div>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              <img src={Image4} className="lg:mx-0 mx-auto" alt="" />
            </div>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Forex Market Real-Time WebSocket</p>
              <p className='bold heading5'>Institutional Level Connection</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 offers you a public server, VPS, and dedicated server for your applications. Edit, manage and build your own plans on your private server. Change the incoming data structure in minutes.</p>
              <div className='grid lg:grid-cols-2 body2 pb-20'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Forex Aggregates</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Limit Up-Downs</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Real-Time Quote Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Forex Imbalansess</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> 1ms low latency option</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='max-w-[1440px] mx-auto'/>
      {/* CRYPTO */}
      
      <BannerContainerWithCol options={options3} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>

      <div className='lg:text-left text-center pt-8 lg:px-20'>
        <div className='grid grid-rows-2'>
          <div className='grid lg:grid-cols-2'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Crypto Data Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Live crypto data in Real-Time streaming within milliseconds, and boost your application today with Rot3's WebSocket & API.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical Order Books</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Details</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Tick data</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Historical End of Day Data</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Aggregates</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Status</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <img src={Image5} className="lg:float-right lg:mx-0 mx-auto" alt="" />
            </div>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              <img src={Image6} className="lg:mx-0 mx-auto" alt="" />
            </div>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Crypto Market Real-Time WebSocket</p>
              <p className='bold heading5'>Institutional Level Connection</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 offers you a public server, VPS, and dedicated server for your applications. Edit, manage and build your own plans on your private server. Change the incoming data structure in minutes.</p>
              <div className='grid lg:grid-cols-2 body2 pb-20'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Real-Time Trade Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Aggregates</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Limit Up & Downs</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Real-Time Quote Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Imbalansess</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> 1ms low latency option</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='max-w-[1440px] mx-auto'/>
      {/* INDEXES */}

      <BannerContainerWithCol options={options4} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>

      <div className='lg:text-left text-center pt-8 lg:px-20'>
        <div className='grid grid-rows-2'>
          <div className='grid lg:grid-cols-2'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Indices Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 has more than 1600+ index market data in real-time. You can get the last price for a symbol with this endpoint.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Aggregates</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <img src={Image7} className="lg:float-right lg:mx-0 mx-auto" alt="" />
            </div>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              <img src={Image8} className="lg:mx-0 mx-auto" alt="" />
            </div>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Crypto Market Real-Time WebSocket</p>
              <p className='bold heading5'>Institutional Level Connection</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 offers you a public server, VPS, and dedicated server for your applications. Edit, manage and build your own plans on your private server. Change the incoming data structure in minutes.</p>
              <div className='grid lg:grid-cols-2 body2 pb-20'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Real-Time Trade Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Crypto Aggregates</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> 1ms low latency option</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='max-w-[1440px] mx-auto'/>
      {/* EFTS */}

      <BannerContainerWithCol options={options5} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>

      <div className='lg:text-left text-center pt-8 lg:px-20'>
        <div className='grid grid-rows-2'>
          <div className='grid lg:grid-cols-2'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>ETFs Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 has more than 2900+ ETF (Exchange Traded Fund) market data in real-time. You can get the last price for a symbol with this endpoint.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Last Price</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Market Aggregates</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <img src={Image9} className="lg:float-right lg:mx-0 mx-auto" alt="" />
            </div>
          </div>
          <div className='grid lg:grid-cols-2'>
            <div>
              <img src={Image10} className="lg:mx-0 mx-auto" alt="" />
            </div>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>ETFs Market Real-Time WebSocket</p>
              <p className='bold heading5'>Institutional Level Connection</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 offers you a public server, VPS, and dedicated server for your applications. Edit, manage and build your own plans on your private server. Change the incoming data structure in minutes.</p>
              <div className='grid lg:grid-cols-2 body2 pb-20'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Real-Time Trade Price</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> EFTs Aggregates</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> 1ms low latency option</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='max-w-[1440px] mx-auto'/>
      {/* WALLET */}

      <BannerContainerWithCol options={options6} />

      <hr className='max-w-[1440px] mx-auto pb-10'/>

      <div className='lg:text-left text-center lg:pb-16 pb-8 lg:px-20'>
          <div className='grid lg:grid-cols-2'>
              <div className='grid lg:grid-rows-4 gap-6  text-rot3-deep-green'>
                  <div>
                      <p className='heading8'>Global Financial Data</p>
                      <p className='body1'>Reach world wide forex, stocks and crypto fundamental data</p>
                  </div>
                  <div>
                      <p className='heading8'>Company Valuation</p>
                      <p className='body1'>There are everything require for valuation Financial Statements; Income Sheets, Balance Sheet, Cash Flow, Market Capitalization, News, Company Statement, Key Metrics</p>
                  </div>
                  <div>
                      <p className='heading8'>Enterprise Level Valuation</p>
                      <p className='body1'>Reach Institutional funds, ETF and Mutual holders, Daily and Intraday Indicators and everything relevant to higher level valuation</p>
                  </div>
                  <div>
                      <p className='heading8'>Financial Calendars</p>
                      <p className='body1'>Catch everything happening in the world economy with, Economic & IPO calendar, Stock Splits, Dividends, Earnings</p>
                  </div>
              </div>
              <div>
                  <div className='lg:row-span-2'>
                      <img className='mx-auto' src={Image11} alt="" />
                  </div>
              </div>
          </div>
          <hr className='max-w-[1440px] mx-auto mt-12'/>
          <div className='grid lg:grid-cols-2 py-20'>
            <div>
              <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Crypto Data Market JSON API</p>
              <p className='bold heading5'>Unlimited Access</p>
              <p className='text-[#8E9498] body1 py-4'>Rot3 has the most accurate financial data API with historical data. We are updating our financial statements in real-time, every statements is audited, standardized, and up to date.</p>
              <div className='grid lg:grid-cols-2 body2'>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Economic Calendar</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Company Details</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Sector Performance</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Most gainer, most loser & active companies</li>
                  </ul>
                </div>
                <div>
                  <ul className='bold'>
                    <li><img className='inline-block' src={GreenTick} alt="" />Stock News</li>
                    <li><img className='inline-block' src={GreenTick} alt="" />Daily Indicators</li>
                    <li><img className='inline-block' src={GreenTick} alt="" />Statements (Cashflow, balance, sheet etc.)</li>
                  </ul>
                </div>
              </div>
              <button className='bg-rot3-deep-green text-rot3-malachite body1 py-2 px-4 rounded-lg hover:bg-[#0A5F5F] mt-4'>
                Learn More
              </button>
            </div>
            <div>
              <img src={Image12} className="float-right" alt="" />
            </div>
          </div>
      </div>

      {/* <div>
        <div className='grid lg:grid-cols-10 gap-8 py-6 lg:mx-0 mx-4 lg:px-20'>
            <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-lg shadow-2xl text-center lg:col-span-3'>
              <div className='p-8'>
                <p className='heading5 text-rot3-deep-green'>UNLIMITED</p>
                <p className='heading9 text-rot3-deep-green'>Starting at</p>
                <p className='heading8 text-rot3-deep-green'>$999 <span className='font-normal text-sm'>/ month</span></p>
              </div>
              <div className='w-full bg-rot3-malachite py-4'>
                <p className='heading8 text-white'>Subscribe Now ＞</p>
              </div>
              <div className='p-8'>
                <ul className='list-disc body1 text-left pl-8'>
                  <li>Real-Time Data</li>
                  <li>Web Socket</li>
                  <li>Unlimited API Calls</li>
                  <li>Historical Data</li>
                  <li>Private Server Option</li>
                  <li>Premium Support</li>
                </ul>
              </div>
            </div>

            <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-lg shadow-2xl text-center lg:col-span-4'>
              <div className='p-8'>
                <p className='heading5 text-rot3-deep-green'>SINGLE SITE</p>
                <p className='heading9 text-rot3-deep-green'>Starting at</p>
                <p className='heading8 text-rot3-deep-green'>$899 <span className='font-normal text-sm'>/ month</span></p>
              </div>
              <div className='w-full bg-rot3-malachite py-4'>
                <p className='heading8 text-white'>Subscribe Now ＞</p>
              </div>
              <div className='p-8'>
                <ul className='list-disc body1 text-left pl-8'>
                  <li>Real-Time Data</li>
                  <li>100% U.S Market Coverage</li>
                  <li>Web Socket</li>
                  <li>Unlimited API Calls</li>
                  <li>19 Years Historical Data</li>
                  <li>HTTPS Encryption</li>
                  <li>Private Server Option</li>
                  <li>Premium Support</li>
                </ul>
              </div>
            </div>

            <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-lg shadow-2xl text-center lg:col-span-3'>
              <div className='p-8'>
                <p className='heading5 text-rot3-deep-green'>LIFETIME</p>
                <p className='heading9 text-rot3-deep-green'>Starting at</p>
                <p className='heading8 text-rot3-deep-green'>$599 <span className='font-normal text-sm'>/ month</span></p>
              </div>
              <div className='w-full bg-rot3-malachite py-4'>
                <p className='heading8 text-white'>Subscribe Now ＞</p>
              </div>
              <div className='p-8'>
                <ul className='list-disc body1 text-left pl-8'>
                  <li>Delayed Data</li>
                  <li>Web Socket</li>
                  <li>Unlimited API Calls</li>
                  <li>Historical Data</li>
                  <li>HTTPS Encryption</li>
                  <li>Private Server Option</li>
                </ul>
              </div>
            </div>
        </div>
      </div> */}

      <div>
        <div className='grid lg:grid-cols-7 gap-8 items-center pb-16'>
          <div className='lg:col-span-3'>
            <img className='lg:float-right mx-auto' src={Image13} alt="" />
          </div>
          <div className='lg:col-span-4 text-rot3-deep-green lg:text-left text-center'>
            <p className='heading4'>Flexible Pricing</p>
            <p className='heading9'>Access to fundamental data sets with ROT3 subscription</p>
            <button className='bg-rot3-malachite text-white body1 py-2 px-4 mt-3 rounded-lg'>
              See Pricing
            </button>
          </div>
        </div>
      </div>

      <div>
        <p className='heading3 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green text-center'>Reach ROT3 Data Specialists</p>
        <div className='grid lg:grid-cols-2 lg:gap-0 gap-10 items-center text-center py-16 lg:px-20'>
          <div className='max-w-[500px] lg:mx-0 mx-auto'>
            <p className='heading8 text-rot3-deep-green'>Consultation - Reach to a data advisor, just in minutes</p>
            <form action="">
              <input class="shadow appearance-none border rounded w-full py-2 px-3 my-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="E-mail"/>
              
              <div className='grid grid-cols-2 text-left'>
                <label class="md:w-2/3 block text-gray-500 font-bold inline-block">
                <input class="mr-2 leading-tight" type="checkbox"/>
                <span class="body1 text-rot3-limestone">
                  Real-Time
                </span>
                </label>

                <label class="md:w-2/3 block text-gray-500 font-bold inline-block">
                <input class="mr-2 leading-tight" type="checkbox"/>
                <span class="body1 text-rot3-limestone">
                  Historical
                </span>
                </label>

                <label class="md:w-2/3 block text-gray-500 font-bold">
                <input class="mr-2 leading-tight" type="checkbox"/>
                <span class="body1 text-rot3-limestone">
                  Delayed
                </span>
                </label>

                <label class="md:w-2/3 block text-gray-500 font-bold">
                <input class="mr-2 leading-tight" type="checkbox"/>
                <span class="body1 text-rot3-limestone">
                  Custom
                </span>
                </label>
              </div>
              <button className='bg-rot3-deep-green text-rot3-malachite body1 py-2 w-full mt-6 rounded-lg hover:bg-[#0A5F5F]'>
                  Submit
              </button>
            </form>
          </div>
          <div className='lg:border-l-2 lg:border-black lg:pl-24'>
            <p className='heading8'>Book a Call with our Sales Team</p>
            <p className='body1 text-gray-500 py-4'>Rot3 data specialists will help you to get more suitable market data feeds regarding your needs quickly</p>
            <button className='bg-rot3-malachite text-white body1 py-2 w-full mt-3 rounded-lg'>
              <p>See Pricing ＞</p>
            </button>
          </div>
        </div>
      </div>
  </>
  )
}
