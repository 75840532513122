import React from 'react'
import Logo from "../assets/images/rot3-logo.svg"

export const PageNotFound = () => {
  return (
    <div className='grid items-center h-screen bg-gradient-to-r from-rot3-malachite to-rot3-snow'>
        <div>
            <img className='mx-auto' src={Logo} alt="" />
            <p className='heading5 bg-clip-text text-transparent bg-gradient-to-r from-rot3-deep-green text-center to-rot3-deep-green'>Page Not Found :(</p>
        </div>
    </div>
    
  )
}
