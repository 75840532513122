import React from 'react';
import './App.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import LayoutWebsite from './components/LayoutWebsite';
import { HomePage } from './pages/HomePage';
import { About } from './pages/About';
import { Solutions } from './pages/Solutions';
import { DataProvider } from './pages/DataProvider';
import { NftWalletServices } from './pages/NftWalletServices';
import { Blockchain } from './pages/Blockchain';
// import { News } from './pages/News';
import { Careers } from './pages/Careers';
// import { NewsContent } from './pages/NewsContent';
// import { Marketing } from './pages/Marketing';
import { Marketing2 } from './pages/Marketing2';
import { ContactUs } from './pages/ContactUs';
// import { ForexLegalSolutions } from './pages/ForexLegalSolutions';
// import { ForexTechSolutions } from './pages/ForexTechSolutions'
import { PageNotFound } from './pages/PageNotFound';
import Rotpay from './pages/Rotpay';
import Rot3Wallet from './pages/Rot3Wallet';
import LayoutWebsiteV2 from './components/LayoutWebsiteV2';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<LayoutWebsite />}>

                <Route index element={<HomePage />} />
                <Route path="about" element={<About />}></Route>
                {/* <Route path="news" element={<News />}></Route> */}
                {/* <Route path="news-content" element={<NewsContent />}></Route> */}

                <Route path='/blockchain-solutions'>
                  <Route path="solutions" element={<Solutions />}></Route>
                  <Route path="blockchain" element={<Blockchain />}></Route>
                  <Route path="nft-wallet-services" element={<NftWalletServices />}></Route>
                </Route>

                <Route path="data-provider" element={<DataProvider />}></Route>
                <Route path='rotpay' element={<Rotpay />}></Route>
                {/* <Route path='/forex-solutions'>
                  <Route path="forex-legal-solutions" element={<ForexLegalSolutions />}></Route>
                  <Route path="forex-tech-solutions" element={<ForexTechSolutions />}></Route>
                </Route> */}

                <Route path='/marketing'>
                  {/* <Route path="marketing-platform" element={<Marketing />}></Route> */}
                  <Route path="logo-website-design" element={<Marketing2 />}></Route>
                </Route>

                <Route path="careers" element={<Careers />}></Route>
                <Route path="contact-us" element={<ContactUs />}></Route>

            </Route>
            <Route path="/" element={<LayoutWebsiteV2 />}>
            < Route path='rot3wallet' element={<Rot3Wallet />}></Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
