import React from "react";

const Block = ({ icon, title }) => (
    <div className="bg-[#CDE8E2] w-full py-10 rounded-xl">
        <img className="mx-auto pb-4" src={icon} alt={title} />
        <p className="heading8 text-rot3-deep-green">{title}</p>
    </div>
);

const Blocks = ({ blocks }) => {
    return (
        <div className="container-full bg-rot3-header-bg1">
            <div className="container-max mx-auto py-12 xl:px-48 md:px-36 px-12">
                <div className="grid xl:grid-cols-3 md:grid-cols-2 justify-items-center text-center gap-4">
                    {blocks.map((block, index) => (
                        <Block key={index} icon={block.icon} title={block.title} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Blocks;