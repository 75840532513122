import React from 'react'
import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const BannerContainer = ({ options, children }) => {
    const { width } = useWindowDimensions();
    let container = options.container ? options.container : {};
    return (
        <div className={`container-full z-[0] relative bg-no-repeat bg-cover ${options.mainZindex ? options.mainZindex : 'z-10'}`} style={{ backgroundImage: `url("${width > 768 || !options.mobileImage ? options.image : options.mobileImage}")` }}>
            {options.imageGradient && <div className='absolute h-full w-full z-20' style={{ background: `${options.imageGradient}` }}></div>}
            <div className='container-max py-6 m-auto flex'>
                <div className=
                    {`z-30 ${container.textAlign ? container.textAlign : 'text-center'}
                    ${container.width ? container.width : 'w-full'}
                    ${container.margin ? container.margin : 'm-auto'}
                    ${container.padding ? container.padding : 'padding-0'}`} >
                    <div className={`${container.marginY ? options.container.marginY : ''}`} >
                        {options.header &&
                            <div className='mb-6' >
                                <h1 className=
                                    {`${options.header.size ? options.header.size : 'text-2xl'} 
                    ${options.header.bold ? options.header.bold : 'font-bold'}
                    ${options.header.color ? options.header.color : 'text-white'}
                    ${options.header.width ? options.header.width : 'w-full'} 
                    ${options.header.lineHeight ? options.header.lineHeight : ''} 
                    ${options.header.align ? options.header.align : 'text-center'}`}>{options.header.text}</h1>
                            </div>}
                        {options.subHeader &&
                            <div className={`${options.subHeader.margin ? options.subHeader.margin : 'mb-6'}`} >
                                <h2 className=
                                    {`${options.subHeader.size ? options.subHeader.size : 'text-normal'} 
                                    ${options.subHeader.bold ? options.subHeader.bold : 'font-normal'} 
                                    ${options.subHeader.color ? options.subHeader.color : 'text-white'}
                                    ${options.subHeader.width ? options.subHeader.width : 'w-full'} 
                                    ${options.subHeader.lineHeight ? options.subHeader.lineHeight : ''} 
                                    ${options.subHeader.align ? options.subHeader.align : 'text-center'}`}>{options.subHeader.text}
                                </h2>
                            </div>
                        }
                        {options.button &&
                            <div className={options.button.padding}>
                                <a href={options.button.href || '#'} className={`
                                    ${options.button.bgcolor}
                                    ${options.button.textcolor}
                                    ${options.button.bordercolor ? `border-2 ${options.button.bordercolor}` : ""}
                                    ${options.button.hover ? `${options.button.hover}` : 'hover:bg-monstrade-blue'}
                                    ${options.button.textSize ? `${options.button.textSize}` : 'lg:text-2xl md:text-xl '}
                                    ease-in
                                    duration-150
                                    lg:py-2 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                    
                                    {options.button.text}
                                </a>
                            </div>}
                        {options.button2 && <div className={options.button2.padding}>
                            <a href={options.button2.href || '#'} className={`
                                    ${options.button2.bgcolor}
                                    ${options.button2.textcolor}
                                    ${options.button2.bordercolor ? `border-2 ${options.button2.bordercolor}` : ""}
                                    ${options.button2.textSize ? `${options.button2.textSize}` : 'lg:text-2xl md:text-xl '}
                                    hover:bg-monstrade-blue
                                    ease-in
                                    duration-150
                                    lg:py-4 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                {options.button2.text}
                            </a>
                        </div>}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}