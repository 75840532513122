import React, { useRef, useState, useEffect } from 'react';
import useOnScreen from '../hooks/onScreen';

const containerStyle = {
  border: '1px solid silver',
  background: '#003C3C'
};

// bg-gradient-to-r from-rot3-logo-color1 to-rot3-logo-color2

const contentStyle = {
  background: 'linear-gradient(117.84deg, #003C3C 9.72%, #00DC82 94.67%)',
  height: '14px',
  textAlign: 'center',
  lineHeight: '24px',
  fontFamily: 'sans-serif',
  transition: '1s ease-in-out'
};

const ProgressBar = ({ progress }) => {
  const [currentWidth, setCurrentWidth] = useState(0)
  const ref = useRef(null);
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setCurrentWidth(`${progress}%`)
      }, 200);
    }

    if (!isVisible) {
      setCurrentWidth(0)
    }
  }, [isVisible])

  return (
    <div ref={ref} className='md:mr-[15%] mx-[5%]'>
      <p className='text-right heading8 text-rot3-deep-green'>{progress}%</p>
      <div className='rounded-3xl' style={containerStyle}>
        <div className='rounded-3xl' style={{ ...contentStyle, width: currentWidth }}>
        </div>
      </div>
      {/* {isVisible &&
      } */}
    </div>
  );
};

export const AnimatedBar = ({ props }) => {
  const [progress, setProgress] = React.useState(props);
  return (
    <div>
      <ProgressBar progress={progress} />
      {/* <div>
        <button onClick={() => setProgress(0)}>0%</button>
        <button onClick={() => setProgress(5)}>5%</button>
        <button onClick={() => setProgress(15)}>15%</button>
        <button onClick={() => setProgress(50)}>50%</button>
        <button onClick={() => setProgress(75)}>75%</button>
        <button onClick={() => setProgress(100)}>100%</button>
    </div> */}
    </div>
  );
};
