import React from 'react'
import { BannerContainer } from '../components/BannerContainer';
import Blocks from '../components/Blocks';
import TextAreaWithIcon from '../components/TextAreaWithIcon';
import InfoCard from '../components/InfoCard'

import SolutionsBanner from '../assets/images/solutions-banner.png'
import Image1 from '../assets/images/solutions-image-1.png'
import Image2 from '../assets/images/solutions-image-2.png'
import Image3 from '../assets/images/solutions-image-3.png'
// import Image4 from '../assets/images/solutions-image-4.png'
import Image5 from '../assets/images/solutions-image-5.svg'
import Image6 from '../assets/images/solutions-image-6.png'

import BugIcon from '../assets/icons/bug-icon.png'
import PenetrationIcon from '../assets/icons/penetration-icon.png'
import KeyIcon from '../assets/icons/key-icon.png'

import ContractIcon from '../assets/icons/contract-icon.png'
import PerpetualIcon from '../assets/icons/perpetual-icon.png'
import CryptoIcon from '../assets/icons/cryptocurrency-icon.png'

import GreenBtc from '../assets/icons/green-btc.svg'
import GreenChain from '../assets/icons/green-chain.png'
import GreenWallet from '../assets/icons/green-wallet2.png'

import BitcoinIco from "../assets/icons/green-btc.png"
import DollarIco from "../assets/icons/green-dollar.png"
import PhoneIco from "../assets/icons/green-phone.png"
import DexIco from "../assets/icons/green-db.png"
import ContractIco from "../assets/icons/green-file.png"
import WalletIco from "../assets/icons/green-wallet.png"

const options = {
    image: SolutionsBanner,
    container: {
        width: 'lg:w-1/2 w-full',
        padding: 'lg:h-[500px]',
        marginY: 'my-12',
        margin: 'lg:ml-36'
    },
    header: {
        text: 'Decentralize Exchange with DEX Platforms.',
        size: 'heading2 ',
        align: 'lg:text-left text-center max-w-[500px]',
        color: 'text-rot3-deep-green'
    },
    subHeader: {
        text: "Rot3's High level DEX platform will give you a chance to provide to your clients fast trade, easy execution and secure platform.",
        size: 'heading9',
        align: 'lg:text-left text-center max-w-[500px]',
        bold: 'text-bold',
        margin: 'mb-10',
        color: 'text-rot3-deep-green'
    },
    button: {
        padding: "lg:float-left",
        bordercolor: "border-rot3-deep-green hover:border-rot3-malachite",
        textcolor: "text-rot3-deep-green",
        hover: "hover:text-white",
        text: "Contact Us",
    }
}

const textAreaOptions = [
    {
        image: BugIcon,
        header: "Bug - Bounty",
        description: "Our internal security audit team will conduct a bug bounty program for the completed beta exchange to find out configuration errors and software bugs that have slipped from developers.",
    },
    {
        image: PenetrationIcon,
        header: "Penetration Test",
        description: "Our external expert team will ensure whether there is any security malfunctioning to be taken care of while moving the developed exchange to the next levels.",
    },
    {
        image: KeyIcon,
        header: "Cyber Security Score Check",
        description: "We proceed a deep cyber security score check, by ensuring the presence of SSL, Cookie Security, 2FA, X-Frame, Strict-Transport-Security, Anti-Phishing Protection, AML, Device Management, SPF, WAF and various other factors.",
    },
]

const textAreaOptions2 = [
    {
        image: ContractIcon,
        header: "Future Contracts",
        description: "Our crypto exchange software with future contracts can provide an option for traders to buy and sell assets at a specified time and price in future.",
    },
    {
        image: PerpetualIcon,
        header: "Perpetual Contracts",
        description: "Our crypto exchange software with perpetual acontracts can generate legal agreements for traders to buy and sell assets in future with no expiry date.",
    },
    {
        image: CryptoIcon,
        header: "Cryptocurrency Options",
        description: "We build crypto exchange platforms with options trading which gives traders the rights on buying and selling crypto assets at specified time in future.",
    },
]

const blocksData = [
    { icon: BitcoinIco, title: "Decentralized Exchange Script" },
    { icon: DollarIco, title: "Decentralized Exchange Software" },
    { icon: PhoneIco, title: "DApp Application" },
    { icon: DexIco, title: "DeFi Based DEX" },
    { icon: ContractIco, title: "Smart Contract Development" },
    { icon: WalletIco, title: "Crypto Wallet Development" },
];


export const Solutions = () => {
    return (
        <>
            <BannerContainer options={options} />

            <div className='py-24 lg:px-20'>
                <p className='heading4 text-rot3-deep-green text-center'>Decentralized Exchange Development</p>
                <div className='grid lg:grid-cols-2 gap-4 lg:text-left text-center items-center pt-8'>
                    <div>
                        <img className='mx-auto' src={Image1} alt="" />
                    </div>
                    <div>
                        <p className='body1 text-rot3-deep-green pb-4'>Rot3, a pioneer in the crypto industry, offers development services for decentralized exchange (DEX) scripts. With automated swapping and smart contract technology, Bitdeal assists customers worldwide in launching their own DEX platforms, eliminating the need for intermediaries and facilitating cryptocurrency exchanges. These development services were introduced in early 2020.</p>
                        <button className='bg-rot3-deep-green text-rot3-malachite body1 py-2 px-4 rounded-lg hover:bg-[#0A5F5F]'>
                            Get Offer
                        </button>
                        <button className='bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 rounded-lg ml-4 hover:shadow-lg'>
                            Get in Touch
                        </button>
                    </div>
                </div>
            </div>

            <div className='bg-rot3-snow text-center text-rot3-deep-green pt-12'>
                <p className='heading4'>Benefits of DEX Development</p>
                <p className='heading9 lg:w-3/4 w-11/12 mx-auto'>DEXs can get over the problems that you can face with on the CEXs and trades works on the smart contracts and executed automatically. </p>
                <Blocks blocks={blocksData} />
            </div>

            <div>
                <div className='grid lg:grid-cols-2 gap-8  text-rot3-deep-green items-center py-10 lg:px-20'>
                    <div className='max-w-[520px] lg:text-left text-center'>
                        <p className='body2 text-rot3-malachite leading-4'>Centralized<br /> Exchange</p>
                        <p className='heading5 py-4'>Build Your Own Cryptocurrency Exchange</p>
                        <p className='body1'>Being a Torchbearer in Cryptocurrency Exchange Development Bitdeal assures you to create a secured, 10X faster, high ROI-based Centralized, Decentralized, and Hybrid Cryptocurrency Exchanges with cutting edge technologies.</p>
                        <button className='bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 rounded-lg hover:shadow-lg mt-8'>
                            Contact Us
                        </button>
                    </div>
                    <div>
                        <img className='mx-auto' src={Image2} alt="" />
                    </div>
                </div>
            </div>

            <div className='bg-rot3-deep-green lg:h-[212px] text-white text-center py-8'>
                <p className='heading5'>Cryptocurrency Exchange Development Company</p>
                <p className='body1 max-w-[1100px] mx-auto'>Rot3 – As a Leading Cryptocurrency Exchange Development Company, we guarantee our valuable clients to build secure, faster, and next-gen cryptocurrency exchanges by availing our white label cryptocurrency exchange software development services. We have extended our services to 100+ countries around the world. Our team of experts is always ready to undergo deep research on any complex requirements and  can guarantee you to deliver results on expected time frame with ‘0’ bugs.</p>
            </div>

            <div>
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center text-rot3-deep-green items-center py-10'>
                    <div>
                        <img className='mx-auto' src={Image3} alt="" />
                    </div>
                    <div className='max-w-[520px]'>
                        <p className='heading5 py-4'>White Label Cryptocurrency Exchange Software</p>
                        <p className='body1'>White Label Cryptocurrency Exchange Software – To Setup, Fast performing, Secured, Multi-Lingual, Cryptocurrency Exchange Platform Powered With Advanced Order Matching Engine, IEO,Smart Contract, ERC -20, TRX Tokens, OTC, DEX, P2P Trading Features.<br /><br /> Our white label cryptocurrency exchange software basic features : KYC/AML, 2FA, Integrated Cryptocurrency Wallet, Merchant API’s, Liquidity API’s, Advanced CMS, Advanced Admin Console, Margin Trading, Cryptocurrency Staking and more premium features listed in the right here.</p>
                        <button className='bg-white border-2 border-rot3-deep-green text-rot3-deep-green body2 py-2 px-4 rounded-lg hover:shadow-lg mt-8'>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <div className="mx-auto text-center text-rot3-deep-green py-8 bg-rot3-snow lg:px-20">
                    <p className='heading7'>We Create Exchanges With High Security Standards</p>
                    <p className='body1 pb-8'>We guarantee that our cryptocurrency exchange development process is completely acknowledging the cryptocurrency exchange security standard, So the developed cryptocurrency exchange would be fully hack proof, and will be entirely preventive towards future hi-tech security attacks. We often undergo a deep analysis of past, present, and future cyber security failures on global cryptocurrency exchanges, and will keep the results to take preventive measures while developing a new crypto exchange.</p>
                    <div className="grid lg:grid-cols-3 lg:text-left text-center justify-items-center gap-10 mx-8">
                        {textAreaOptions.map((item) => {
                            return <TextAreaWithIcon item={item} hclass={'heading8 lg:text-left text-center text-rot3-deep-green font-semibold text-xl py-4 mt-[-44px]'} dclass={'mx-auto lg:text-left text-rot3-deep-green body1 text-center lg:mt-[-10px] mt-[-20px]'} iclass={'lg:mx-0 mx-auto'} />
                        })}
                    </div>
                </div>
            </div>

            <div>
                <p className='heading5 text-rot3-deep-green text-center max-w-[800px] mx-auto pt-24'>Our White Label Cryptocurrency Exchange Software Features</p>
                <InfoCard />
            </div>

            <div className='py-8 bg-rot3-snow lg:px-20'>
                <p className='heading5 text-rot3-deep-green text-center max-w-[700px] mx-auto'>Derivative Trading Features Of Our Crypto Exchange Software</p>
                <div className="grid lg:grid-cols-3 lg:text-left text-center justify-items-center gap-10 mx-8 py-8">
                    {textAreaOptions2.map((item) => {
                        return <TextAreaWithIcon item={item} hclass={'heading8 text-center text-rot3-malachite font-semibold text-xl py-4 '} dclass={'mx-auto text-rot3-deep-green body1 text-center lg:mt-[-10px] mt-[-20px]'} iclass={'mx-auto'} />
                    })}
                </div>
            </div>

            {/* <div className='py-16'>
            <p className='heading4 text-rot3-deep-green leading-tight text-center mx-auto lg:w-3/4'>Our Top On Market Cryptocurrency Exchange Clone Scripts</p>
            <p className='text-center body1 lg:w-11/12 mx-auto py-8'>At bitdeal we support new cryptocurrency exchange startups with warm hands and encourage them to quickly kick start their own cryptocurrency exchange with instant solutions. To Reduce the time and development effort, we provide white label cryptocurrency exchange clone script that costs less resources, low investment and will result in immediate market cap as well as help you to acquire a quick brand reputation among the competitive cryptocurrency exchange market.</p>
            <img className='mx-auto py-4' src={Image4} alt="" />
        </div> */}

            <div className='text-rot3-deep-green text-center pt-16 lg:px-20'>
                <p className='heading5 pb-4'>Cryptocurrency Exchange App Development</p>
                <p className='body1 w-11/12 mx-auto'>Cryptocurrency Exchange App – A Lucrative business solution to grab more audience or customers to an exchange business with less marketing efforts and COI. At bitdeal we convert your entire exchange interface into a complete, user friendly, bug free mobile app with mandatory features.</p>
                <div className='grid lg:grid-cols-2 gap-8 items-center pt-12 lg:absolute'>
                    <div>
                        <img className='mx-auto' src={Image5} alt="" />
                    </div>
                    <div className='lg:text-left text-center mx-auto pb-20'>
                        <img className='inline-block' src={GreenBtc} alt="" />
                        <p className='lg:inline-block heading9 pl-2'>Decentralized Exchange Development</p> <br /><br />
                        <img className='inline-block' src={GreenChain} alt="" />
                        <p className='lg:inline-block heading9 pl-2'>Cryptocurrency Wallet APP</p> <br /><br />
                        <img className='inline-block' src={GreenWallet} alt="" />
                        <p className='lg:inline-block heading9 pl-2'>Decentralized Exchange App</p>
                    </div>
                </div>
                <div>
                    <img className='2xl:pt-[412px] lg:pt-[430px]' src={Image6} alt="" />
                </div>
            </div>
        </>
    )
}
