import React from 'react'

export const BannerContainerWithCol = ({ options }) => {
    const containerStyle = {}
    if(options.isCurved){containerStyle.borderRadius = '0% 0% 40% 40%'}
    if(options.bgImage){containerStyle.backgroundImage = options.bgImage}
    
    return (
        <div className='bg-white max-w-[1440px] m-auto overflow-hidden'>
            <div className={`${options.bgColor ? options.bgColor : 'bg-white'} w-[140%] m-auto ml-[-20%]`} style={containerStyle}>
                <div className='max-w-[71.4289%] m-auto'>
                    <div className='flex lg:flex-row flex-col m-auto py-24 sm:px-20 px-4'>
                        <div className='lg:basis-2/4' >
                            {options.header &&
                                <h1 className={options.header.class ? options.header.class : ''} >
                                    {options.header.text ? options.header.text : ''}
                                </h1>
                            }
                            {options.subHeader &&
                                <p className={options.subHeader.class} >
                                    {options.subHeader.text ? options.subHeader.text : ''}
                                </p>
                            }
                            {options.button &&
                                <div className='flex' >
                                    <a className={options.button.class ? options.button.class : ''} >
                                        {options.button.text ? options.button.text : ''}
                                    </a>
                                </div>
                            }
                        </div>
                        <div className='lg:basis-2/4 flex' >
                            <img className='m-auto lg:pl-10 lg:pt-0 pt-10 pl-0' src={options.image} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
