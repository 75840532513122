import React from 'react'
import { BannerContainerWithCol } from '../components/BannerContainerWithCol'
import Blocks from '../components/Blocks';

import bannerImage from '../assets/images/rot3wallet-banner.svg'
import Background from '../assets/images/rot3-bg.png'
import Iphone from '../assets/images/rot3wallet-iphone.png'
import Rot3Gif from '../assets/rot3wallet-gif.gif'

import Image1 from '../assets/images/rot3-wallet-img1.png'
import Image2 from '../assets/images/rot3-wallet-img2.png'
import Image3 from '../assets/images/rot3-wallet-img3.png'

import Ico1 from '../assets/icons/visa-ico.svg'
import Ico2 from '../assets/icons/master-ico.svg'
import Ico3 from '../assets/icons/apple-ico.svg'
import Ico4 from '../assets/icons/stripe-ico.svg'
import Ico5 from '../assets/icons/skrill-ico.svg'
import Ico6 from '../assets/icons/paypal-ico.svg'

import GreenIco1 from '../assets/icons/rot3wallet-ico1.svg'
import GreenIco2 from '../assets/icons/rot3wallet-ico2.svg'
import GreenIco3 from '../assets/icons/rot3wallet-ico3.svg'
import GreenIco4 from '../assets/icons/rot3wallet-ico4.svg'

import Green1 from '../assets/icons/rot3-wallet-green1.svg'
import Green2 from '../assets/icons/rot3-wallet-green2.svg'
import Green3 from '../assets/icons/rot3-wallet-green3.svg'
import Green4 from '../assets/icons/rot3-wallet-green4.svg'
import Green5 from '../assets/icons/rot3-wallet-green5.svg'
import Green6 from '../assets/icons/rot3-wallet-green6.svg'

const Rot3Wallet = () => {

    const options = {
        image: bannerImage,
        header: {
          text: 'All Your Crypto Transactions in One App with ROT Wallet',
          class: 'heading2 max-w-[600px] bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:mx-0 mx-auto lg:text-left text-center leading-[70px]'
        },
        subHeader: {
          text: "Integrate ROT3 Wallet with your apps to make or receive payments and transfers!",
          class: "heading9 text-black leading-[30px] lg:text-left text-center mb-4 py-4"
        },
        button: {
          text: "CONTACT US",
          class: "bg-rot3-deep-green text-rot3-malachite body2 py-2 px-4 rounded-lg lg:mx-0 mx-auto hover:bg-[#0A5F5F] leading-10"
      }
    }

    const blocksData = [
        { icon: Green1, title: "Quick Crypto Purchase" },
        { icon: Green2, title: "Security" },
        { icon: Green3, title: "Global Accessebility" },
        { icon: Green4, title: "Low Costs" },
        { icon: Green5, title: "Mobile Usability" },
        { icon: Green6, title: "Privacy" },
    ];

  return (
    <>
        <div className='max-w-[1340px] mx-auto'>
            <BannerContainerWithCol options={options} />
        </div>
        

        <div className={"container-full text-center text-white py-20 items-center justify-center z-0 relative bg-rot3-deep-green lg:bg-no-repeat lg:bg-cover lg:px-20"} style={{ backgroundImage: `url("${Background}")` }}>
            <div className='max-w-[1340px] mx-auto'>
                <p className='text-4xl font-bold pb-8 max-w-[450px] mx-auto'>A Bridge Between the <span className='underline decoration-green-500'>Real World</span> and <span className='underline decoration-green-500'>Web 3</span></p>
                <p className='text-xl'>Rebuild your financial world with ROT3 Wallet. Experience a fully decentralized wallet for making purchases, receiving and sending payments, transferring cryptocurrencies, or investing in NFTs and cryptocurrencies! With ROT3 Wallet, everything is in your hands!</p>
            </div>
        </div>

        <div className='max-w-[1340px] mx-auto'>
            <div className='grid lg:grid-cols-2 py-16 lg:text-left text-center justify-center items-center'>
                <div>
                    <img className='max-w-[400px] mx-auto' src={Rot3Gif} alt="" />
                </div>
                <div>
                    <p className='text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Explore the Decentralized Network with Confidence</p>
                    <p className='texl-2xl text-[#6F6F6F] py-6'>ROT3 Wallet supports dozens of cryptocurrencies and a wide range of essential Web3 applications. Your crypto assets are entirely under your control, meaning you have full authority over them. With ROT3 Wallet, you can not only store your digital assets securely but also access and use them easily whenever and wherever you want. As an ideal tool for taking advantage of all the benefits offered by the modern crypto ecosystem, ROT3 Wallet is a perfect solution for both beginners and experienced users.</p>
                    <a href="/"><p className='text-green-500 underline'>Get Info Now</p></a>
                </div>
            </div>
        </div>

        <div className='bg-[#FAFAFA] text-center py-16 rounded-lg'>
            <div className='max-w-[1340px] mx-auto'>
                <p className='text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Buy Crypto Quickly</p>
                <p className='texl-2xl text-[#6F6F6F] py-12 mx-4'>With ROT3 Wallet, even if you have no cryptocurrency balance, you can instantly purchase crypto using various payment methods such as credit cards, Apple Pay, Google Pay, PayPal, Skrill, Stripe, and bank transfers. This flexibility allows you to carry out transfers and make payments seamlessly, regardless of your current crypto balance.</p>
                <div className='grid lg:grid-cols-6 grid-cols-2 gap-6'>
                    <img className='mx-auto' src={Ico1} alt="" />
                    <img className='mx-auto' src={Ico2} alt="" />
                    <img className='mx-auto' src={Ico3} alt="" />
                    <img className='mx-auto' src={Ico4} alt="" />
                    <img className='mx-auto' src={Ico5} alt="" />
                    <img className='mx-auto' src={Ico6} alt="" />
                </div>
            </div>
        </div>

        <div className='py-8 max-w-[1240px] mx-auto'>
            <p className='text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green py-8 lg:text-left text-center'>Why ROT3 Wallet</p>
            <div className='grid lg:grid-cols-3 items-center lg:text-left text-center'>
                <div className='lg:col-span-2'>
                    <div className='grid grid-cols-2 gap-6 text-[#737373]'>
                        <div>
                            <img className='lg:mx-0 mx-auto' src={GreenIco1} alt="" />
                            <p className='font-bold text-black py-2 text-lg lg:max-w-[140px]'>Crypto Transfers</p>
                            <p>With your ROT3 Wallet, accept cryptocurrency payments or transfer crypto to different wallets.</p>
                        </div>

                        <div>
                            <img className='lg:mx-0 mx-auto'src={GreenIco2} alt="" />
                            <p className='font-bold text-black py-2 text-lg lg:max-w-[140px]'>Quick Crypto Purchase</p>
                            <p>If you have no balance, quickly purchase cryptocurrency to continue with your transactions.</p>
                        </div>

                        <div>
                            <img className='lg:mx-0 mx-auto'src={GreenIco3} alt="" />
                            <p className='font-bold text-black py-2 text-lg lg:max-w-[140px]'>Accept Crypto Payment</p>
                            <p>Integrate ROT3 Wallet into your applications and start accepting cryptocurrency payments from your customers.</p>
                        </div>

                        <div>
                            <img className='lg:mx-0 mx-auto' src={GreenIco4} alt="" />
                            <p className='font-bold text-black py-2 text-lg lg:max-w-[140px]'>Completely Decentralized</p>
                            <p>Leverage decentralized finance (DeFi) to conduct your investments and business transactions in the most secure and fastest way possible.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <img className='mx-auto' src={Iphone} alt="" />
                </div>
            </div>
        </div>

        <div className='bg-rot3-snow text-center text-rot3-deep-green pt-12'>
            <div className='max-w-[1340px] mx-auto'>
                <p className='heading4'>Advantages of ROT3 Wallet</p>
                <p className='heading9 lg:w-3/4 w-11/12 mx-auto'>Here are some of the advantages ROT3 Wallet can offer for your payments</p>
                <Blocks blocks={blocksData} />
            </div>
        </div>

        <div>
            <div className='grid lg:grid-cols-2 max-w-[1340px] mx-auto items-center py-8'>
                <div>
                    <img className='mx-auto' src={Image1} alt="" />
                </div>
                <div className='bg-[#F8F8F8] rounded-xl max-w-[600px] lg:py-12 py-4 lg:px-6 px-2 lg:text-left text-center'>
                    <p className='text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Access Your Crypto Wallet from Any Device You Choose</p>
                    <p className='text-[#6F6F6F] py-4'>Use ROT3 Wallet in any way you prefer from the web, mobile, or tablet. Manage your cryptocurrencies however you like. They're with you in your pocket, on your desk, or during your travels...</p>
                    <button className='bg-rot3-deep-green text-rot3-malachite body2 px-4 rounded-lg lg:mx-0 mx-auto hover:bg-[#0A5F5F] leading-10'>Get Info Now</button>
                </div>
            </div>

            <div className='grid lg:grid-cols-2 max-w-[1340px] mx-auto items-center py-8'>
                <div className='bg-[#F8F8F8] rounded-xl max-w-[600px] mx-auto lg:py-12 py-4 lg:px-6 px-2 lg:text-left text-center'>
                    <p className='text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Integration Solutions For Every Situation And Platform</p>
                    <p className='text-[#6F6F6F] py-4'>Businesses and individual users can integrate RotPay's APIs into their e-commerce websites or mobile applications and immediately start receiving cryptocurrency payments. Simply adding their RotPay account API key to their websites and applications is sufficient to begin.</p>
                </div>
                <div>
                    <img className='mx-auto' src={Image2} alt="" />
                </div>
            </div>

            <div className='grid lg:grid-cols-2 max-w-[1340px] mx-auto items-center py-8'>
                <div>
                    <img className='mx-auto' src={Image3} alt="" />
                </div>
                <div className='bg-[#F8F8F8] rounded-xl max-w-[600px] lg:py-12 py-4 lg:px-6 px-2 lg:text-left text-center'>
                    <p className='text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green'>Access Your Crypto Wallet from Any Device You Choose</p>
                    <p className='text-[#6F6F6F] py-4'>Use ROT3 Wallet in any way you prefer from the web, mobile, or tablet. Manage your cryptocurrencies however you like. They're with you in your pocket, on your desk, or during your travels...</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Rot3Wallet