import React from 'react'

import { BannerContainer } from '../components/BannerContainer';
import Input from '../components/Input'

import ContactUsBanner from '../assets/images/contactUs-banner.png'

import ContactWp from '../assets/icons/contact-wp.svg'
import ContactWpIco from '../assets/icons/contact-wp-ico.svg'
import ContactSendMail from '../assets/icons/contact-sendMail.svg'
import ContactLocation from '../assets/icons/concat-location.svg'

export const ContactUs = () => {

    const options = {
        image: ContactUsBanner,
        container: {
            width: 'lg:w-1/2 w-full',
            padding: 'lg:h-[500px]',
            marginY: 'my-20',
            margin: 'lg:ml-36'
        },
        header: {
            text: 'Contact Us!',
            size: 'heading2',
            align: 'lg:text-left text-center pt-16',
            color: 'text-white'
        },
        subHeader: {
            text: 'Stock data in real-time streaming and historical API. Build charts, applications and platforms with one subscription.',
            size: 'heading9',
            align: 'lg:text-left text-center',
            bold: 'text-bold',
            margin : 'mb-10',
            color: 'text-white'
        },
       
    }

    const nameInputProps = {
        id: "",
        label: "First name",
        width: "lg:w-11/12 w-full",
        height: "h-[48px]",
        placeholder: "Type your name",
      }
      
      const lastNameInputProps = {
        id: "",
        label: "Last name",
        width: "w-full",
        height: "h-[48px]",
        placeholder: "Type your last name",
      }
      
      const emailInputProps = {
        id: "",
        label: "Email",
        width: "lg:w-11/12 w-full",
        height: "h-[48px]",
        placeholder: "Type your email",
      }
      
      const numberInputProps = {
        id: "",
        label: "Number",
        width: "w-full",
        height: "h-[48px]",
        placeholder: "Type your phone number",
      }
      
      const areaInputProps = {
        id: "",
        label: "Message",
        width: "w-full",
        height: "h-[200px] ",
        placeholder: "Please, type your message",
      }


  return (  
    <>
    <BannerContainer options={options}/>
    
    <div className="py-20">
        <h3 className='heading4 mx-auto text-center text-rot3-deep-green'>GET IN TOUCH </h3>
        <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4 text-center p-6'>

        <div className='flex flex-col items-center bg-[#F2FCF7] p-6 rounded-lg shadow-md'>
            <img className='pb-4' src={ContactWp} alt="" />
            <div className='textArea '>
            <h2 className='heading8 mb-4 text-rot3-deep-green leading-tight'>Contact Us With Whatsapp</h2> 
            <img className='inline-block pr-2' src={ContactWpIco} alt="" />
            <p className='inline-block text-rot3-limestone body1'>+44 7307 289360</p>
            </div>
        </div>
        <div className='flex flex-col items-center bg-[#F2FCF7] p-6 rounded-lg shadow-md'>
            <img className='pb-4' src={ContactSendMail} alt="" />
            <div className='textArea '>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>Send a Mail</h2> 
            <p className='inline-block text-rot3-limestone body1'>info@rot3.com</p>
            </div>
        </div>
        <div className='flex flex-col items-center bg-[#F2FCF7] p-6 rounded-lg shadow-md'>
            <img className='pb-4' src={ContactLocation} alt="" />
            <div className='textArea '>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>Our Office</h2> 
            <p className='inline-block text-rot3-limestone body1'>London, Eversholt,
            Regend House</p>
            </div>
        </div>
        <div className='flex flex-col items-center bg-[#F2FCF7] p-6 rounded-lg shadow-md'>
            <img className='pb-4' src={ContactWp} alt="" />
            <div className='textArea '>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>Our Office</h2> 
            <p className='inline-block text-rot3-limestone body1'>Çiftehavuzlar Mh. Eski Londra Asfaltı Cd. Kuluçka Merkezi A1 Blok No:151 1/C Esenler/İstanbul</p>
            </div>
        </div>

        </div>

    </div>
    <div className='pb-20'>

        <iframe className='mx-auto lg:w-[600px] sm:w-[400px] w-[280px] lg:h-[350px] h-[200px]' title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9934.267469148017!2d-0.1193227!3d51.5028154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c7be3d9791%3A0x67bb55b5c4867f8c!2sLondon%20Eye%20ticket%20office!5e0!3m2!1sen!2str!4v1699001904824!5m2!1sen!2str" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div className='py-8 mx-[10%]'>
        <div className=' bg-white shadow-2xl rounded-md w-full pt-8'>
            <div className='grid lg:grid-cols-2 mx-[16%]'>
                <div>
                    <div className="py-3" ><Input props={nameInputProps}/></div>
                    <div className="py-3" ><Input props={emailInputProps}/></div>
                </div>
                <div>
                    <div className="py-3" ><Input props={lastNameInputProps}/></div>
                    <div className="py-3" ><Input props={numberInputProps}/></div>
                </div>
            </div>
            <div className='mx-[16%]'>
                <div className="py-3" ><Input props={areaInputProps}/></div>
                <div className="pt-4 pb-16 lg:text-left text-center" >
                  <button className='bg-rot3-deep-green text-rot3-malachite body2 py-2 px-4 rounded-lg hover:bg-[#0A5F5F]'>
                    Send
                  </button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

