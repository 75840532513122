import React from 'react'

import { Carousel } from 'react-responsive-carousel'

// Images

import Banner from '../assets/images/nft-wallet-services-banner.png'
import Image1 from '../assets/images/sl-wallet-service-img1.png'
import Image2 from '../assets/images/sl-wallet-service-img2.png'
import Image3 from '../assets/images/sl-wallet-service-img3.png'
import Image4 from '../assets/images/sl-wallet-service-img4.png'
import Image5 from '../assets/images/sl-wallet-service-img5.png'
// import Image6 from '../assets/images/sl-wallet-service-img6.png'

import ButtonImg1 from '../assets/images/ws1.png'
import ButtonImg2 from '../assets/images/ws2.png'
import ButtonImg3 from '../assets/images/ws3.png'
import ButtonImg4 from '../assets/images/ws4.png'
import ButtonImg5 from '../assets/images/ws5.png'
import ButtonImg6 from '../assets/images/ws6.png'
import ButtonImg7 from '../assets/images/ws7.png'
import ButtonImg8 from '../assets/images/ws8.png'
import ButtonImg9 from '../assets/images/ws9.png'
import ButtonImg10 from '../assets/images/ws10.png'
import ButtonImg11 from '../assets/images/ws11.png'
import ButtonImg12 from '../assets/images/ws12.png'
import ButtonImg13 from '../assets/images/ws13.png'
import ButtonImg14 from '../assets/images/ws14.png'
import ButtonImg15 from '../assets/images/ws15.png'


export const NftWalletServices = () => {

  return(
    <>
        <div className='bg-rot3-deep-green py-8 lg:mt-0 mt-16'>
            <img className='mx-auto' src={Banner} alt="" />

            <div>
                <p className='heading2 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-white text-center lg:w-3/4 w-11/12 py-8 mx-auto'>Wallet & NFT products that seamlessly integrate with your blockchain project</p>
                <p className='body1 text-white text-center'>Onboard your users with our easy to imlement Wallet services. And experience the full potential with our NFT tools and NFT.</p>
                <img className='mx-auto lg:py-16 py-8' src={Image1} alt="" />

                <div className='grid lg:grid-cols-2'>
                    <div>
                        <img className='mx-auto' src={Image2} alt="" />
                    </div>
                    <div className='lg:pt-16 pt-4 text-center lg:text-left'>
                        <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-white'>Blockchain Agnostic</p>
                        <p className='text-white body1 pb-8 max-w-[550px]'>Supporting the biggest blockchain layers for our set of blockchain products. We focus on both fungible and non-fungible (NFTs). <br/><br/>
                        Preferred partners of Polygon, Binance, Avalanche and Hedera.</p>
                        <div className='pt-4 text-center lg:text-left'>
                            <img className='inline-block' src={ButtonImg1} alt="" />
                            <img className='inline-block px-4' src={ButtonImg2} alt="" />
                            <img className='inline-block' src={ButtonImg3} alt="" />
                        </div>
                    </div>
                </div>

                <div className='grid lg:grid-cols-2 lg:ml-20'>
                    <div className='lg:pt-16 pt-4 text-center lg:text-left'>
                        <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-white'>Tools to build securely</p>
                        <p className='text-white body1 pb-8 max-w-[550px]'>With our powerful wallet, minting and market APIs, you have a trustworthy partner to scale your blockchain project. Instead of assuming we are secure, we encourage Ethical hackers to battle test our defenses every single day!</p>
                        <div className='lg:pt-12 pt-4'>
                            <img className='inline-block' src={ButtonImg4} alt="" />
                            <img className='inline-block px-4' src={ButtonImg5} alt="" />
                            <img className='inline-block' src={ButtonImg6} alt="" />
                        </div>
                    </div>
                    <div className='py-12'>
                        <img className='mx-auto' src={Image3} alt="" />
                    </div>
                </div>
            </div>
        </div>

{/* Carousel */}

<Carousel autoPlay={false} infiniteLoop={true} interval={5000} transitionTime={1000} showThumbs={false}>
        <div>
            <div className='bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:text-left text-center'>
                <div className='grid lg:grid-cols-2 items-center h-[600px]'>
                    <div className=' lg:w-10/12 w-11/12 mx-auto'>
                        <p className='heading4 text-white'>Wallet Solutions</p>
                        <p className='body1 text-white pb-4'>Onboard your users with our wallet solutions. Choose our widget or API solution as your custody wallet provider to scale your project.</p>
                        <div>
                            <img className='inline-block' src={ButtonImg7} alt="" />
                            <img className='inline-block px-4' src={ButtonImg8} alt="" />
                            <img className='inline-block' src={ButtonImg9} alt="" />
                        </div>
                        <button className='bg-white body2 py-2 px-4 rounded-lg mt-4 text-rot3-deep-green hover:shadow-lg mb-4'>
                            LEARN MORE
                        </button>
                    </div>
                    <div>
                        <img src={Image4} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className='bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:text-left text-center'>
                <div className='grid lg:grid-cols-2 items-center h-[600px]'>
                    <div className=' lg:w-10/12 w-11/12 mx-auto'>
                        <p className='heading4 text-white'>NFT Marketplace</p>
                        <p className='body1 text-white pb-4'>Sell your NFTs on the YTUNFT and show your project and work to our fast frowing network of users. Unlock the network effect of a secondary market on our marketplace and get your royalty fees on every sale.</p>
                        <div>
                            <img className='inline-block' src={ButtonImg10} alt="" />
                            <img className='inline-block px-4' src={ButtonImg11} alt="" />
                            <img className='inline-block' src={ButtonImg12} alt="" />
                        </div>
                        <button className='bg-white body2 py-2 px-4 rounded-lg mt-4 text-rot3-deep-green hover:shadow-lg mb-4'>
                            LEARN MORE
                        </button>
                    </div>
                    <div>
                        <img src={Image5} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className='bg-gradient-to-r from-rot3-malachite to-rot3-deep-green lg:text-left text-center'>
                <div className='grid lg:grid-cols-2 items-center h-[600px]'>
                    <div className=' lg:w-10/12 w-11/12 mx-auto'>
                        <p className='heading4 text-white'>NFT Tools</p>
                        <p className='body1 text-white pb-4'>Use our secure NFT tools to monetize your project. With our tools you can create, manage, distribute and secure yout NFTs on our platform.</p>
                        <div>
                            <img className='inline-block' src={ButtonImg13} alt="" />
                            <img className='inline-block px-4' src={ButtonImg14} alt="" />
                            <img className='inline-block' src={ButtonImg15} alt="" />
                        </div>
                        <button className='bg-white body2 py-2 px-4 rounded-lg mt-4 text-rot3-deep-green hover:shadow-lg mb-4'>
                            LEARN MORE
                        </button>
                    </div>
                    <div>
                        <img src={Image5} alt="" />
                    </div>
                </div>
            </div>
        </div>
</Carousel>        
    </>
  )

}