import React, { useState } from 'react'

// import Logo from '../assets/images/rot3-logo.svg'
import { ResponsiveCarousel } from '../components/ResponsiveCarousel'
import TextAreaWithIcon from '../components/TextAreaWithIcon'

import Wsolana from '../assets/logos/w-solana.png'
import Wpolygon from '../assets/logos/w-polygon.png'
import Wtezos from '../assets/logos/w-tezos.png'
import Wfantom from '../assets/logos/w-fantom.png'
import Wbinance from '../assets/logos/w-binance.png'
import Whypeledger from '../assets/logos/w-hyperledger.png'
import Wavalanche from '../assets/logos/w-avalanche.png'
import Wheco from '../assets/logos/w-heco.png'

import solana from '../assets/logos/solana.png'
import polygon from '../assets/logos/polygon.png'
import tezos from '../assets/logos/tezos.png'
import fantom from '../assets/logos/fantom.png'
import binance from '../assets/logos/binance.png'
import hypeledger from '../assets/logos/hyperledger.png'
import avalanche from '../assets/logos/avalanche.png'
import heco from '../assets/logos/heco.png'

import btcicon from '../assets/icons/btc-icon.png'
import cubeicon from '../assets/icons/cube-icon.png'
import connectionicon from '../assets/icons/connection-icon.png'
import pairicon from '../assets/icons/pair-icon.png'
import cubesicon from '../assets/icons/cubes-icon.png'
// import twocubes from '../assets/icons/twocubes-icon.png'

import Image1 from '../assets/images/homepage-image.png'
import Image2 from '../assets/images/homepage-image-2.png'
import Image3 from '../assets/images/homepage-image-3.png'
import Image4 from '../assets/images/homepage-image-4.png'
import Image5 from '../assets/images/homepage-image-5.png'
import Image6 from '../assets/images/homepage-image-6.png'
import Image7 from '../assets/images/homepage-image-7.png'
import Video from '../assets/homepage-video.mp4'

import StockIcon from '../assets/icons/stock-data-icon.png'
import CryptoIcon from '../assets/icons/crypto-icon.png'
import EftIcon from '../assets/icons/eft-icon.png'
import ShapesIcon from '../assets/icons/shapes-icon.png'
import ForexIcon from '../assets/icons/forex-icon.png'
import IndicesIcon from '../assets/icons/indices-icon.png'
import SquaresIcon from '../assets/icons/squares-icon.png'

import Erc20 from '../assets/icons/erc20.svg'
import Erc721 from '../assets/icons/erc721.svg'
import Erc223 from '../assets/icons/erc223.svg'
import Erc777 from '../assets/icons/erc777.svg'
import Erc827 from '../assets/icons/erc827.svg'
import Erc1155 from '../assets/icons/erc1155.svg'

import Ico1 from '../assets/icons/white-feature1.svg'
import Ico5 from '../assets/icons/white-feature2.svg'
import Ico9 from '../assets/icons/white-feature3.svg'
import Ico4 from '../assets/icons/white-feature4.svg'
import Ico8 from '../assets/icons/white-feature5.svg'
import Ico3 from '../assets/icons/white-feature6.svg'
import Ico7 from '../assets/icons/white-feature7.svg'
import Ico2 from '../assets/icons/white-feature8.svg'
import Ico6 from '../assets/icons/white-feature9.svg'
import Ico10 from '../assets/icons/white-feature10.svg'

import greenIco1 from '../assets/icons/green-ico1.png'
import greenIco2 from '../assets/icons/green-ico2.png'
import greenIco3 from '../assets/icons/green-ico3.png'

import AboutUs1 from '../assets/icons/about-us1.png'
import AboutUs2 from '../assets/icons/about-us2.png'
import AboutUs3 from '../assets/icons/about-us3.png'

import WhatsappIcon from '../assets/icons/whatsapp-icon.png'

import Rotpay from '../assets/images/homepage_rotpay.png'
import Rotwallet from '../assets/images/rotwallet-homepage.png'

import Visa from '../assets/images/homepage_visa.png'
import GPay from '../assets/images/homepage_gpay.png'
import Stripe from '../assets/images/homepage_stripe.png'
import ApplePay from '../assets/images/homepage_applePay.png'
import Revolut from '../assets/images/homepage_Revolut.png'
import Paypal from '../assets/images/homepage_paypal.png'
import Bank from '../assets/images/homepage_bank.png'
import Skrill from '../assets/images/homepage_skrill.png'

import { IoMdArrowForward } from "react-icons/io";

import FiTechPro from '../assets/images/homepage_fitech.png'
import GreenTick from '../assets/icons/homepage_check-circle.svg'

export const HomePage = () => {

  const [displayed, setDisplayed] = useState(null);

  const textAreaOptions = [
    {
      image: btcicon,
      header: "Cryptocurrency Exchange Development",
      description: "We build your own cryptocurrency exchange from scratch with our crypto exchange script.",
    },
    {
      image: cubeicon,
      header: "Decentralized Exchange Development",
      description: "We build a fully decentralized cryptocurrency exchange with our ready to launch a decentralized exchange script.",
    },
    {
      image: connectionicon,
      header: "Hybrid Exchange Development",
      description: "We Create a cryptocurrency exchange that can function as both centralized and decentralized at one single trading platform",
    },
    {
      image: pairicon,
      header: "P2P Exchange Development",
      description: "Acquire our p2p exchange script, to start creating your own peer to peer cryptocurrency trading platform like Local Bitcoins.",
    },
    {
      image: cubesicon,
      header: "DeFi DEX Development",
      description: "Provide an open platform for crypto traders to trade defi tokens by building a hassle free defi dex platform with defi dex script.",
    },
  ]

  const textAreaOptions2 = [
    {
      image: Erc20,
      header: "Token Development",
      description: "ERC 20 comprises the list of rules and regulations for creating tokens and smart contracts on ethereum blockchains. It defines how tokens transfers and token access between various addresses.",
    },
    {
      image: Erc721,
      header: "Token Development",
      description: "ERC 721 is a more advanced token standard which is “non-fungible”. In the ERC 721, each and every token is completely non-interchangeable and unique on its own.",
    },
    {
      image: Erc223,
      header: "Token Development",
      description: "ERC 223 is an ethereum token standard proposed with the main intention of preventing the token losses while transferring tokens. Each token transfer is handled as an event like ether transactions.",
    },
    {
      image: Erc777,
      header: "Token Development",
      description: "ERC 777 is a new and advanced token standard proposed to eliminate the send-tokens-to-contract bugs present in the ERC 20 standard.",
    },
    {
      image: Erc827,
      header: "Token Development",
      description: "It is an extended standard of the ever known ERC20, this standard enables the basic features to transfer tokens, also it executes calls inside the transfer and approval.",
    },
    {
      image: Erc1155,
      header: "Token Development",
      description: "This standard creates the feasibility to manage any kind of token type. In this contract we can include both fungible, non-fungible, debt or any kind of token types.",
    },
  ]

  const textAreaOptions3 = [
    {
      image: greenIco1,
      header: "Get started for free, upgrade as you grow",
      description: "Get access to all market data in less than minutes, easy-to-integrate with simple and effective APIs documentation, so you can focus on your project.",
    },
    {
      image: greenIco2,
      header: "Fast and scalable streaming market data",
      description: "Select the best server location for you to decrease latency. Use WebSocket streaming to get the data in real-time so you can build your own trade platforms.",
    },
    {
      image: greenIco3,
      header: "All in one financial data made easy",
      description: "Institutional level financial data in realtime and historical stock, forex, crypto, ETF, index prices, and more - available with bundles or individual.",
    },
  ]

  return (
    <>
      <ResponsiveCarousel />
      {/* <p className='heading1'>header1</p>
      <p className='heading2'>header2</p>
      <p className='heading3'>header3</p>
      <p className='heading4'>header4</p>
      <p className='heading5'>header5</p>
      <p className='heading6'>header6</p>
      <p className='heading7'>header7</p>
      <p className='heading8'>header8</p>
      <p className='heading9'>header9</p>

      <p className='body1'>body1</p>
      <p className='body2'>body2</p>
      <p className='body3'>body3</p>
      <p className='body4'>body4</p> */}
      <div className='container-full bg-rot3-deep-green'>
        <div className='py-6 w-full mx-auto'>
          <div className='text-center grid lg:grid-cols-8 sm:grid-cols-2 lg:gap-0 gap-6 items-center mx-20'>
            <div
              onMouseEnter={() => setDisplayed('solana')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'solana' ? solana : Wsolana} alt="rot solana logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('polygon')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'polygon' ? polygon : Wpolygon} alt="rot3 polygon logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('tezos')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'tezos' ? tezos : Wtezos} alt="rot3 tezos logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('fantom')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'fantom' ? fantom : Wfantom} alt="rot3 fantom logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('hypeledger')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'hypeledger' ? hypeledger : Whypeledger} alt="rot3 hyperledger logo" className='inline-block w-[130px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('binance')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'binance' ? binance : Wbinance} alt="rot3 binance logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('heco')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'heco' ? heco : Wheco} alt="rot3 heco logo" className='inline-block w-[110px]' />
            </div>

            <div
              onMouseEnter={() => setDisplayed('avalanche')}
              onMouseLeave={() => setDisplayed(null)}
            >
              <img src={displayed === 'avalanche' ? avalanche : Wavalanche} alt="rot3 avalanche logo" className='inline-block w-[110px]' />
            </div>

          </div>
        </div>
      </div>

      <div>
        <div className='grid lg:grid-cols-4 gap-16 max-w-[1440px] mx-auto text-center text-rot3-deep-green py-8 lg:px-20'>
          <div className='lg:border-r'>
            <p className='heading5'>21,000+</p>
            <p className='heading9'>Trusted ROT3</p>
          </div>
          <div className='lg:border-r'>
            <p className='heading5'>345M+</p>
            <p className='heading9'>Requests/Day</p>
          </div>
          <div className='lg:border-r'>
            <p className='heading5'>1MS</p>
            <p className='heading9'>Server Latency</p>
          </div>
          <div>
            <p className='heading5'>25+</p>
            <p className='heading9'>Server Location</p>
          </div>
        </div>
      </div>

      <div>
        <div className='grid lg:grid-cols-2 items-center justify-center max-w-[1440px] mx-auto py-16 lg:px-20 lg:gap-0 gap-10'>
          <div className='text-rot3-deep-green lg:text-left text-center'>
            <p className='heading4 leading-tight pb-2'>Step Into Our World Excellence Through Innovation Harness</p>
            <p className='body1'>Harness The Power Of Your Brokerage With Fully Customized, Technology-Advenced, Premium Trading Platforms</p>
          </div>
          <div>
            <img className='mx-auto' src={Image1} alt="" />
          </div>
        </div>
      </div>

      <div>
        <div className="container-max max-w-[1440px] mx-auto py-16 lg:px-20">
          <div className="grid lg:grid-cols-3 justify-items-center gap-[45px]">
            {textAreaOptions.map((item) => {
              return <TextAreaWithIcon item={item} hclass={'heading8 lg:text-left text-center text-rot3-limestone font-semibold text-xl py-4'} dclass={'mx-auto lg:text-left text-rot3-deep-green body1 text-center'} iclass={'lg:mx-0 mx-auto'} />
            })}
          </div>
        </div>
      </div>

      <div className=' max-w-[1440px] mx-auto pt-8'>
        <video autostart autoPlay muted loop src={Video} type="video/mp4" />
      </div>

      <div className={"container-full grid lg:grid-cols-2 items-center justify-center z-0 relative bg-rot3-deep-green lg:bg-no-repeat lg:bg-cover lg:px-20"} style={{ backgroundImage: `url("${Image2}")` }}>
        <div className='lg:p-8 lg:pl-20 p-4'>
          <p className='text-white heading3 leading-tight lg:text-left text-center'>We provide any type of product you need</p>
          <p className='text-white heading9 lg:text-left text-center py-4'>Just choose the ones you want and start to work with them</p>
          <div className='grid sm:grid-cols-2 gap-4 text-white'>
            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={StockIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Stock Data</p>
                <p className='body3'>US and Global Market Data</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={ForexIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Forex</p>
                <p className='body3'>Global Forex Data Coverage</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={CryptoIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Crypto</p>
                <p className='body3'>Large cryptocurrency data coverage</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={IndicesIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Indexes</p>
                <p className='body3'>Global Index Market Data</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={EftIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>ETF</p>
                <p className='body3'>Global Exchange Traded Fund and Mutual Fund Data</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={SquaresIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Fundamentals</p>
                <p className='body3'>Statements, Calendar and More</p>
              </div>
            </div>

            <div className='grid grid-cols-5'>
              <div className='col-span-1'>
                <img className='inline-block' src={ShapesIcon} alt="" />
              </div>
              <div className='col-span-4'>
                <p className='inline-block body2'>Widgets</p>
                <p className='body3'>Tickers, Converters, Charts and more</p>
              </div>
            </div>
          </div>
        </div>

        <div className='self-center p-4'>
          <div className='bg-white bg-opacity-20 rounded-2xl max-w-[480px] mx-auto p-12'>
            <p className='text-rot3-malachite heading5 text-center pb-3'>Who We Are?</p>
            <p className='text-white body1 lg:text-left text-center'>Our goal is to offer the best blockchain and cryptocurrency solutions to any organization or sector looking to change the course of history in the crypto world by embracing cutting-edge trends & technologies.</p>
          </div>
        </div>

      </div>

      {/* fitech */}
      <div className='py-16'>
        <div className='py-10 bg-[#EDEDED] rounded-[32px]'>
          <div className='xl:flex items-start gap-[94px] justify-center p-3'>
            <div className='max-w-[700px] grid grid-row-4 gap-10 xl:mx-0 mx-auto xl:pl-[117px] mb-10'>
              <h3 className='heading4 leading-tight text-black max-w-[700px] xl:text-left text-center'>
                Reshape the Investment World with <span className='text-rot3-malachite'>FiTechPro</span>
              </h3>
              <p className='body1 text-[#7C868B]'>
                <span className='font-bold'>FiTechPro</span> is an application that provides investors with a single platform encompassing many essential features such as news,
                analyses, gamification, trading signals, educational resources, and real-time market monitoring. This comprehensive toolset empowers
                investors to make informed decisions while offering a fun and interactive experience. Discover <span className='font-bold'>FiTechPro</span> to set your financial
                goals and enhance your investment strategies effectively!
              </p>
              <div className='grid grid-cols-2 body1'>
                <div>
                  <ul className='flex flex-col gap-5'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> News</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Trading Signals</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Financial Educations</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Technical Summary</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Technical Analysis</li>
                  </ul>
                </div>
                <div>
                  <ul className='flex flex-col gap-5'>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Fundamental Analysis</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Live Market Datas</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Technical Indicators</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Gamification</li>
                    <li><img className='inline-block' src={GreenTick} alt="" /> Economic Calendar</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='flex xl:flex-col flex-col-reverse gap-3 xl:items-end items-center'>
              <div>
                <a
                  href="https://fitechpro.com"
                  className="inline-flex items-center body2 px-4 py-2 bg-rot3-malachite text-black font-semibold rounded-lg shadow-md hover:bg-rot3-shiny-green focus:outline-none transition-colors duration-200"
                >
                  Explore Now
                  <IoMdArrowForward className="ml-2 h-5 w-5 font-bold" />
                </a>
              </div>
              <img src={FiTechPro} alt="FiTechPro" className='lg:pt-10 xl:mx-0 mx-auto' />
            </div>
          </div>
        </div>
      </div>
      {/* fitech */}
      {/* rotpay */}
      <div className='max-w-[1440px] mx-auto py-16'>
        <div className='py-16 bg-[#EDEDED] rounded-[32px]'>
          <div className='xl:flex items-start gap-[94px] justify-center p-3'>
            <div>
              <img src={Rotpay} alt="Rotpay" className='lg:pt-10 xl:mx-0 mx-auto' />
            </div>
            <div className='max-w-[700px] grid grid-row-4 gap-10 xl:mx-0 mx-auto'>
              <h3 className='heading4 leading-tight text-black max-w-[700px] xl:text-left text-center'>
                Advanced Technology, Seamless Payments with <span className='text-rot3-malachite'>RotPay</span>
              </h3>
              <p className='body1'>
                RotPay is a leading payment solution driving digital transformation worldwide.
                Dedicated to the widespread adoption and acceptance of cryptocurrencies,
                RotPay enables everyone to securely and easily make and receive payments with cryptocurrencies.
              </p>
              <div className='grid grid-cols-4 gap-4 '>
                <img src={Visa} alt="Visa" />
                <img src={GPay} alt="GPay" />
                <img src={Stripe} alt="Stripe" />
                <img src={ApplePay} alt="ApplePay" />
                <img src={Revolut} alt="Revolut" />
                <img src={Paypal} alt="Paypal" />
                <img src={Bank} alt="Bank" />
                <img src={Skrill} alt="Skrill" />
              </div>
              <div className='xl:text-left text-center'>
                <a
                  href="./rotpay"
                  className="inline-flex items-center body2 px-4 py-2 bg-rot3-malachite text-black font-semibold rounded-lg shadow-md hover:bg-rot3-shiny-green focus:outline-none transition-colors duration-200"
                >
                  Explore Now
                  <IoMdArrowForward className="ml-2 h-5 w-5 font-bold" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* rotpay */}

      {/* rotwallet */}
      <div className='max-w-[1440px] mx-auto py-16'>
        <div className='py-16 bg-[#EDEDED] rounded-[32px]'>
          <div className='xl:flex items-start gap-[94px] justify-center items-center p-3'>
            <div className='max-w-[700px] grid grid-row-4 gap-10 xl:mx-0 lg:pl-16 pl-0 mx-auto'>
              <h3 className='heading4 leading-tight text-black max-w-[700px] xl:text-left text-center'>
              Experience Freedom in the Crypto World with <span className='text-rot3-malachite'>ROT3 Wallet</span>
              </h3>
              <p className='body1'>
                Discover new levels of control and flexibility with ROT3 Wallet. Effortlessly manage your cryptocurrencies from home, the office, or on the go. Enjoy seamless access across web, mobile, and tablet devices, with top-notch security features ensuring your digital assets are always at your fingertips. Embrace the freedom of the crypto world with ROT3 Wallet and make every transaction simple and secure.
              </p>
              <div className='xl:text-left text-center'>
                <a
                  href="./rot3wallet"
                  className="inline-flex items-center body2 px-4 py-2 bg-rot3-malachite text-black font-semibold rounded-lg shadow-md hover:bg-rot3-shiny-green focus:outline-none transition-colors duration-200"
                >
                  Explore Now
                  <IoMdArrowForward className="ml-2 h-5 w-5 font-bold" />
                </a>
              </div>
            </div>
            <div>
              <img src={Rotwallet} alt="Rotwallet" className='lg:pt-10 xl:mx-0 mx-auto' />
            </div>
          </div>
        </div>
      </div>
      {/* rotwallet */}

      <div>
        <div className='py-16 lg:px-20'>
          <p className='heading4 leading-tight text-rot3-deep-green max-w-[700px] mx-auto text-center mb-[48px]'>Business Benefits of Smart Contract Development</p>
          <div className='grid lg:grid-cols-3'>
            <div className='text-rot3-deep-green body2 text-center'>
              <div className='bg-rot3-snow h-[51px] pt-3 my-8'><p>Complete Automation</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Elimination Of Middlemen</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Tamper Proof Data Backup</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Immutable And Self-Governing</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3'><p>Use Of Cryptography Technology</p></div>
            </div>
            <div>
              <img className='mx-auto pt-8' src={Image3} alt="" />
            </div>
            <div className='text-rot3-deep-green body2 text-center'>
              <div className='bg-rot3-snow h-[51px] pt-3 my-8'><p>Ultra Speed Execution</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Dispute Resolution</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Secured Transactions</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3 mb-8'><p>Compliance Management</p></div>
              <div className='bg-rot3-snow h-[51px] pt-3'><p>Cost Effective</p></div>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center py-8 lg:px-20'>
        <p className='heading4 leading-tight text-rot3-deep-green pb-4 max-w-[700px] mx-auto'>Ethereum Token Standards We Work</p>
        <div className="grid lg:grid-cols-3 justify-items-center gap-10">
          {textAreaOptions2.map((item) => {
            return <div className='rounded-lg bg-rot3-snow p-8'><TextAreaWithIcon item={item} hclass={'text-center pt-20 heading8 text-rot3-deep-green'} dclass={'mt-10 text-center body1 text-rot3-deep-green'} iclass={'mx-auto'} /></div>
          })}
        </div>
      </div>

      <div className='py-16 lg:px-20'>
        <hr className='border-rot3-limestone border-2' />
      </div>

      <div>
        <div className='py-8 lg:px-20'>
          <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green text-center pb-8'><i>Features of our Blockchain Dapps</i></p>
          <div className='grid lg:grid-cols-2'>
            <div className='grid md:grid-cols-2 gap-4 items-center text-white text-center lg:text-left'>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico1} alt="" />
                <p className='inline-block body1 pl-2'>Hardware Wallet Integration</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico2} alt="" />
                <p className='inline-block body1 pl-2'>Enhanced User Interface</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 pt-5 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico3} alt="" />
                <p className='inline-block body1 pl-2'>Custom Blockchain Dev</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico4} alt="" />
                <p className='inline-block body1 pl-2'>MVP Consulting</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico5} alt="" />
                <p className='inline-block body1 pl-2'>Attractive UI/UX</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 pt-5 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico6} alt="" />
                <p className='inline-block body1 pl-2'>Smart Contract Integration</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico7} alt="" />
                <p className='inline-block body1 pl-2'>API Integration</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center '>
                <img className='inline-block' src={Ico8} alt="" />
                <p className='inline-block body1 pl-2'>Cloud Services Involvement</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico9} alt="" />
                <p className='inline-block body1 pl-2'>Rewards & Collectible Integration</p>
              </div>
              <div className='bg-rot3-deep-green rounded-lg p-4 h-[80px] lg:flex lg:items-center'>
                <img className='inline-block' src={Ico10} alt="" />
                <p className='inline-block body1 pl-2'>Decentralized Storage Medium</p>
              </div>
            </div>
            <div className='lg:order-last order-first'>
              <img className='mx-auto py-8' src={Image4} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className='lg:px-20'>
        <div className='text-center'>
          <p className='heading4 bg-clip-text text-transparent bg-gradient-to-r from-rot3-malachite to-rot3-deep-green pt-8 pb-2'>The Advent of DeFi Revolution</p>
          <p className='heading8 text-rot3-limestone'>DeFi recreates a better world for businesses by abolishing the existing hurdles.</p>
        </div>
        <div className='grid lg:grid-cols-2 gap-4 py-6'>
          <div className='bg-rot3-snow rounded-lg px-12 py-6 max'>
            <p className='heading8 text-rot3-deep-green'>Before DeFI</p>
            <ul className='list-disc text-rot3-deep-green body1 pl-8'>
              <li>Complicated procedure in loans.</li>
              <li>Illiquid financial market.</li>
              <li>Frequent security breach.</li>
              <li>Enormous fee for third-parties.</li>
              <li>Corporate conspiracy</li>
            </ul>
          </div>
          <div className='bg-rot3-deep-green rounded-lg px-12 py-6'>
            <p className='heading8 text-white'>After DeFI</p>
            <ul className='list-disc text-white body1 pl-8'>
              <li>Flash loans in just 15 seconds!</li>
              <li>Automated liquid market.</li>
              <li>Encrypted by security protocols.</li>
              <li>Absolutely ZERO intermediaries.</li>
              <li>100% transparent system.</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div className={"container-full grid lg:grid-cols-2 lg:h-[550px] z-0 relative bg-rot3-deep-green lg:bg-no-repeat lg:bg-cover "} style={{ backgroundImage: `url("${Image5}")` }}>
          <div className='max-w-[600px] lg:p-32 lg:text-left text-center sm:p-16 p-8'>
            <p className='heading6 text-rot3-malachite'>US & Global Stock Data All In One Place</p>
            <p className='text-white body1 py-6 pb-8'>Our real-time streaming and historical API provides stock data that you can use to build charts, applications, and platforms with a single subscription.</p>
            <a className='bg-rot3-malachite text-white rounded-lg p-3 body1' href="./contact-us"><button>Contact Us</button></a>
          </div>
        </div>
      </div>

      <div className='text-center py-20 lg:px-20'>
        <p className='heading4 text-rot3-deep-green max-w-[1200px] mx-auto'>Instant, affordable, fastmarket data solutions for what you need to do</p>
        <p className='heading8 text-rot3-limestone'>Focus on your development, we will handle all of your financial data requirements</p>
        <div className="grid lg:grid-cols-3 justify-items-center py-8 gap-10 mx-8">
          {textAreaOptions3.map((item) => {
            return <TextAreaWithIcon item={item} hclass={'lg:text-left text-center text-rot3-deep-green body2 mt-[-24px]'} dclass={'mx-auto body1 lg:text-left text-rot3-deep-green text-center lg:mt-[-42px] mt-[-20px]'} iclass={'lg:mx-0 mx-auto'} />
          })}
        </div>
      </div>

      <div className='py-8 '>
        <div className={"container-full grid lg:grid-cols-5 items-center lg:text-left text-center gap-4 p-8 z-0 relative bg-rot3-deep-green lg:bg-no-repeat lg:bg-cover lg:px-20"} style={{ backgroundImage: `url("${Image6}")` }}>
          <div className='lg:col-span-1'>
            <img className='mx-auto' src={Image7} alt="" />
          </div>
          <div className='lg:col-span-3'>
            <p className='text-white heading5'>Customer Care</p>
            <p className='text-rot3-malachite body1 lg:max-w-[600px]'>Leverage our team’s vast knowledge and experience in technology, sales & marketing, operations, finance and compliance to grow your business.</p>
          </div>
        </div>
      </div>

      <div className='lg:px-20'>
        <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-6 text-center p-6 items-center'>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6 p-8'>
            <img className='mx-auto' src={AboutUs1} alt="" />
            <p className='heading8 text-rot3-deep-green py-2'>Contact Us Via Whatsapp</p>
            <img className='inline-block' src={WhatsappIcon} alt="" />
            <p className='inline-block body1'>+44 7307 289360</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6 p-8'>
            <img className='mx-auto' src={AboutUs2} alt="" />
            <p className='heading8 text-rot3-deep-green py-2'>Send an E-Mail</p>
            <p className='body1'>info@rot3.com</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6 p-8'>
            <img className='mx-auto' src={AboutUs3} alt="" />
            <p className='heading8 text-rot3-deep-green py-2'>Our Office</p>
            <p className='body1'>London, Eversholt, Regend House</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6 p-8'>
            <img className='mx-auto' src={AboutUs3} alt="" />
            <p className='heading8 text-rot3-deep-green py-2'>Our Office</p>
            <p className='body1'>Çiftehavuzlar Mh. Eski Londra Asfaltı Cd. Kuluçka Merkezi A1 Blok No:151 1/C Esenler/İstanbul</p>
          </div>
        </div>
      </div>
    </>
  )
}



// Price Area
{/* <div>
  <div className='grid lg:grid-cols-10 gap-4 py-6 lg:mx-0 lg:px-28'>
    <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-[24px] shadow-2xl text-center lg:col-span-3'>
      <div className='p-8'>
        <p className='heading5 text-rot3-deep-green'>UNLIMITED</p>
        <p className='heading9 text-rot3-deep-green'>Starting at</p>
        <p className='heading8 text-rot3-deep-green'>$999 <span className='font-normal text-sm'>/ month</span></p>
      </div>
      <div className='w-full bg-rot3-malachite py-4'>
        <p className='heading8 text-white'>Subscribe Now ＞</p>
      </div>
      <div className='p-8'>
        <ul className='list-disc body1 text-left pl-8'>
          <li>Real-Time Data</li>
          <li>Web Socket</li>
          <li>Unlimited API Calls</li>
          <li>Historical Data</li>
          <li>Private Server Option</li>
          <li>Premium Support</li>
        </ul>
      </div>
    </div>

    <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-[24px] shadow-2xl text-center lg:col-span-4'>
      <div className='p-8'>
        <p className='heading5 text-rot3-deep-green'>SINGLE SITE</p>
        <p className='heading9 text-rot3-deep-green'>Starting at</p>
        <p className='heading8 text-rot3-deep-green'>$899 <span className='font-normal text-sm'>/ month</span></p>
      </div>
      <div className='w-full bg-rot3-malachite py-4'>
        <p className='heading8 text-white'>Subscribe Now ＞</p>
      </div>
      <div className='p-8'>
        <ul className='list-disc body1 text-left pl-8'>
          <li>Real-Time Data</li>
          <li>100% U.S Market Coverage</li>
          <li>Web Socket</li>
          <li>Unlimited API Calls</li>
          <li>19 Years Historical Data</li>
          <li>HTTPS Encryption</li>
          <li>Private Server Option</li>
          <li>Premium Support</li>
        </ul>
      </div>
    </div>

    <div className='bg-rot3-snow border-2 border-rot3-limestone rounded-[24px] shadow-2xl text-center lg:col-span-3'>
      <div className='p-8'>
        <p className='heading5 text-rot3-deep-green'>LIFETIME</p>
        <p className='heading9 text-rot3-deep-green'>Starting at</p>
        <p className='heading8 text-rot3-deep-green'>$599 <span className='font-normal text-sm'>/ month</span></p>
      </div>
      <div className='w-full bg-rot3-malachite py-4'>
        <p className='heading8 text-white'>Subscribe Now ＞</p>
      </div>
      <div className='p-8'>
        <ul className='list-disc body1 text-left pl-8'>
          <li>Delayed Data</li>
          <li>Web Socket</li>
          <li>Unlimited API Calls</li>
          <li>Historical Data</li>
          <li>HTTPS Encryption</li>
          <li>Private Server Option</li>
        </ul>
      </div>
    </div>
  </div>
</div> */}
