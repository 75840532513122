import React from "react";
import Logo from '../assets/images/rot3-logo.svg'
import MenuContent from '../features/MenuContent'

// Icons

import Instagram from '../assets/icons/instagram.png'
import Reddit from '../assets/icons/reddit.png'
import Twitter from '../assets/icons/twitter.png'

// import Image1 from '../assets/images/footer-news-1.png'
// import Image2 from '../assets/images/footer-news-2.png'
// import Image3 from '../assets/images/footer-news-3.png'

const navigation = MenuContent()

const socialMedias = [
    { logo: "twitter", link: "https://twitter.com/rot3com", icon: Twitter },
    { logo: "instagram", link: "https://www.instagram.com/rot3com/", icon: Instagram },
    { logo: "reddit", link: "https://www.reddit.com/user/ROT3co/", icon: Reddit },
]

const Footer = () => {
    return (
        <>
            <div className="container-full bg-rot3-snow">
                <div><hr className=" h-px border-0"></hr></div>
                <div className="container-max mx-auto">
                    <footer className="text-left  text-black">
                        <div className="md:mx-6 py-6 lg:text-center md:text-left lg:mx-auto max-w-[1440px]">
                            <div className="grid lg:grid-cols-5 lg:text-left text-center lg:gap-1 gap-4">
                                <div className="lg:col-span-1">
                                    <h6 className="uppercase font-semibold flex 
                                        items-center justify-center lg:justify-start">
                                        <div className="mx-auto">
                                            <img src={Logo} alt="" />
                                        </div>
                                    </h6>
                                    <p className="lg:mb-10 mb-2 text-center lg:mt-6 text-gray-500 body1" >support@rot3.com<br /></p>
                                </div>
                                <div className="lg:col-span-4">
                                    <div className="grid lg:grid-cols-4">
                                        {navigation.map((item, index) => {
                                            if (item.status === false) return <></>
                                            return (
                                                <div className="" key={index}>
                                                    <h6 className="uppercase heading8 text-rot3-deep-green mb-3 flex justify-center lg:justify-start">
                                                        {item.header}
                                                    </h6>
                                                    {item.subItems.map((subItem, sindex) => {
                                                        if (subItem.status === false) return <></>
                                                        return (
                                                            <div key={sindex} >
                                                                <p className="mt-3 mb-1 text-gray-400 body1">
                                                                    {subItem.name.charAt(0).toUpperCase() + subItem.name.slice(1).toLowerCase()}

                                                                </p>
                                                                {subItem.subItems.map((subItem2, sindex2) => {
                                                                    if (subItem2.status === false) return <></>
                                                                    return (
                                                                        <a key={sindex2} href={subItem2.link} className="text-gray-500 block py-1 body1"> {subItem2.name} </a>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                        {/* <div className="lg:col-span-3">
                                        <p className="uppercase heading8 text-rot3-deep-green mb-3 flex lg:text-left text-center justify-center lg:justify-start">NEWS</p>
                                        <div className="grid lg:grid-cols-3">
                                            <div className="grid lg:grid-cols-4">
                                                <div className="lg:col-span-1"><img className="inline-block" src={Image1} alt="" /></div>
                                                <div className="lg:col-span-3"><p className="line-clamp-3 body4 text-rot3-limestone lg:mx-0 mx-auto max-w-[120px]">Meta given 30 days to cease using the name Threads</p></div>
                                            </div>
                                            <div className="grid lg:grid-cols-4">
                                                <div className="lg:col-span-1"><img className="inline-block" src={Image2} alt="" /></div>
                                                <div className="lg:col-span-3"><p className="line-clamp-3 body4 text-rot3-limestone lg:mx-0 mx-auto max-w-[120px]">Meta given 30 days to cease using the name Threads</p></div>
                                            </div>
                                            <div className="grid lg:grid-cols-4">
                                                <div className="lg:col-span-1"><img className="inline-block" src={Image3} alt="" /></div>
                                                <div className="lg:col-span-3"><p className="line-clamp-3 body4 text-rot3-limestone lg:mx-0 mx-auto max-w-[120px]">Meta given 30 days to cease using the name Threads</p></div>
                                            </div>
                                        </div>
                                        <a href="/news"><p className="body2 pt-8 text-rot3-deep-green underline">View all news</p></a>
                                    </div> */}

                                    </div>
                                </div>
                            </div>

                            <div className="lg:m-0 mx-auto h-[50px] flex gap-5" >
                                <hr className="w-96 ml-20 mt-6 border-gray-400 border-1" />
                                {socialMedias.map((item, index) => {
                                    return (

                                        <a key={index} href={item.link} target="_blank" className="m-auto" >
                                            <img src={item.icon} alt="" />
                                        </a>

                                    )
                                })}
                                <hr className="w-96 mr-20 mt-6 border-gray-400 border-1" />
                            </div>


                        </div>
                        <div className="text-center md:p-6 bg-white">
                            <p className="text-sm text-gray-400 " href="https://monstrade.com/">©2023 This website is owned and operated by ROT3 Global Limited.</p>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default Footer;