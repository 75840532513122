import React from 'react';

function Input({props}) {

    const placeholderStyle = `
           .inputField::-webkit-input-placeholder{
                   color: red;
           }`
  return (
    <div className={`${props.width}`}>
      <label className="block text-[#8E9498] font-bold mb-2" htmlFor="example-input">
        {props.label}
      </label>
      <input
        className={` textArea shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="example-input"
        type="text"
        ${props.height}
        `} placeholder={props.placeholder} id={props.id}
      />
      
    </div>
  );
}

export default Input;

