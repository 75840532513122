import React from "react";

import DollarIco from "../assets/icons/dollar.png"
import ClockIco from "../assets/icons/time.png"
import MagicIco from "../assets/icons/magic-stick.png"
import SecurityIco from "../assets/icons/security.png"
import BtcIco from "../assets/icons/btc.png"
import ListIco from "../assets/icons/list.png"

import TriangleIco from "../assets/icons/triangle.png"
import SticksIco from "../assets/icons/sticks.png"
import ShineIco from "../assets/icons/shine.png"
import MedalIco from "../assets/icons/medal.png"
import UserSettingsIco from "../assets/icons/user-settings.png"
import HelpIco from "../assets/icons/help.png"

const InfoCard = () => {
    return(
        <div className="container-full">
            <div className="container-max mx-auto py-24">
                <div className="grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 text-center justify-items-center">
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={DollarIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Crypto Staking</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={ClockIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Advanced CMS</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={MagicIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Integrated Wallet</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={SecurityIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">AML Integration KYC</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={BtcIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Cryptocurrency Price Ticker API</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={ListIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Advanced Order Types</p>
                    </div>
                </div>
                <div className="grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 text-center justify-items-center">
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={TriangleIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Advanced Order Matching Engine</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={SticksIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Customizable Platform For Specific Features</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={ShineIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">500+ Multi Cryptocurrencies Support</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={MedalIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Smart Contract</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={UserSettingsIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">Two Factor Authentication</p>
                    </div>
                    <div className="py-2">
                        <img className="mx-auto pb-2" src={HelpIco} alt="" />
                        <p className="text-rot3-deep-green opacity-50 heading8 xl:mx-10 mx-2">ERC-20 And TRX Tokens Support</p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default InfoCard;




