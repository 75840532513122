import React from "react";
import { Outlet } from "react-router-dom"
import Footer from './Footer';
import Navbar from './Navbar'

const LayoutWebsite = () => {
    return (
        <>
            <Navbar />
            <div className="max-w-[1440px] m-auto p-3" id='main' >
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default LayoutWebsite;