import React from 'react'

import { BannerContainer } from '../components/BannerContainer';
import { AnimatedBar } from '../components/AnimatedBar'
import AboutBanner from '../assets/images/about-banner.png'

import ExpertIcon from '../assets/icons/expertise-icon.png'
import SuccessIcon from '../assets/icons/success-icon.png'
import GroupIcon from '../assets/icons/group-icon.png'
import WorldIcon from '../assets/icons/world-icon.png'

import Image1 from '../assets/images/about-image-1.png'
import Image2 from '../assets/images/about-image-2.png'

import VisionIcon from '../assets/icons/vision-ico.png'
import MissionIcon from '../assets/icons/mission-ico.png'
import ValueIcon from '../assets/icons/values-ico.png'

const options = {
    image: AboutBanner,
    container: {
        width: 'lg:w-1/2 w-full',
        padding: 'lg:h-[500px]',
        marginY: 'my-20',
        margin: 'lg:ml-36'
    },
    subHeader: {
        text: 'Rot3 is a software engineering company for new-age businesses. Our team works with organizations across the globe to design and deliver exceptional digital experiences.',
        size: 'heading9',
        align: 'lg:text-left text-center',
        bold: 'text-bold',
        margin : 'mb-10',
        color: 'text-rot3-deep-green'
    },
}

export const About = () => {
  return (
    <>
        <BannerContainer options={options}/>

        <div className='grid lg:grid-cols-3 gap-6 text-center p-6 items-center lg:px-20'>
          <div className='bg-[#EFEFEF] rounded-lg shadow-xl h-full pt-6 p-8'>
            <p className='heading5 text-rot3-deep-green py-2'>What We Do</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='inline-block body1'>Rot3, The perfect destination for budding crypto entrepreneurs with immense hope and vision. We strive to revolutionize the crypto and blockchain industry with our 360-degree enterprise blockchain solutions and services.</p>
          </div>
          <div className='bg-[#EFEFEF] rounded-lg shadow-xl h-full pt-6'>
            <p className='heading5 text-rot3-deep-green py-2'>Who We Help</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1'>Aspiring entrepreneurs, startups, and business owners can benefit from our services. This clear business model will help you run a successful business now and in the future!</p>
          </div>
          <div className='bg-[#EFEFEF] rounded-lg shadow-xl h-full pt-6'>
            <p className='heading5   text-rot3-deep-green py-2'>Why Choose Us</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1'>Using cutting-edge solutions to help blockchain projects and crypto exchange firms empower their businesses, the Rot3 team is skilled, transparent, and success-oriented. We are here to share our expertise and help you to get better.</p>
          </div>
        </div>

        <div className='grid lg:grid-cols-4 sm:grid-cols-2 gap-6 text-center p-6 items-center max-w-[1280px] mx-auto lg:px-20'>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6'>
            <img className='mx-auto h-24' src={ExpertIcon} alt="" />
            <p className='heading8 text-rot3-malachite py-2 h-24'>3 YEARS OF EXPERTISE</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1 sm:p-8'>We are pleased to share that we have completed 3+ successful years and look forward to many more!</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6'>
            <img className='mx-auto h-24' src={SuccessIcon} alt="" />
            <p className='heading8 text-rot3-malachite py-2 h-24'>20+ SUCCESSFUL PROJECTS</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1 sm:p-8'>We are We engage with customers worldwide and have completed over 20+ projects so far.</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6'>
            <img className='mx-auto h-24' src={GroupIcon} alt="" />
            <p className='heading8 text-rot3-malachite py-2 h-24'>20 EXPERT DEVELOPERS</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1 sm:p-8'>We are a dynamic and passionate team dedicated to assisting our customers in accomplishing their goals.</p>
          </div>
          <div className='bg-rot3-snow rounded-lg shadow-xl h-full pt-6'>
            <img className='mx-auto h-24' src={WorldIcon} alt="" />
            <p className='heading8 text-rot3-malachite py-2 h-24'>10+ GLOBAL CLIENTS</p>
            <hr className='border-2 border-rot3-deep-green w-10/12 mx-auto mb-4' />
            <p className='body1 sm:p-8'>We have proudly serviced over 10+ satisfied customers all around the world with our great products and services.</p>
          </div>
        </div>

        <div>
          <img src={Image1} alt="" />
        </div>

        <div className='grid lg:grid-cols-3 text-center p-6 items-center max-w-[1280px] mx-auto'>
          <div>
            <img className='mx-auto h-24' src={VisionIcon} alt="" />
            <p className='heading8 text-rot3-malachite pt-2 h-12'>Vision</p>
            <hr className='border-1 border-rot3-deep-green w-1/3 mx-auto mb-4' />
            <p className='body1 sm:p-8 h-36'>We are pleased to share that we have completed 3+ successful years and look forward to many more!</p>
          </div>
          <div>
            <img className='mx-auto h-24' src={MissionIcon} alt="" />
            <p className='heading8 text-rot3-malachite pt-2 h-12'>Mission</p>
            <hr className='border-1 border-rot3-deep-green w-1/3 mx-auto mb-4' />
            <p className='body1 sm:p-8 h-36'>We are We engage with customers worldwide and have completed over 20+ projects so far.</p>
          </div>
          <div>
            <img className='mx-auto h-24' src={ValueIcon} alt="" />
            <p className='heading8 text-rot3-malachite pt-2 h-12'>Core Values</p>
            <hr className='border-1 border-rot3-deep-green w-1/3 mx-auto mb-4' />
            <p className='body1 sm:p-8 h-36'>We are a dynamic and passionate team dedicated to assisting our customers in accomplishing their goals.</p>
          </div>
        </div>

        <div>
          <div className='mx-[4%] py-[4%]'>
            <div className='grid lg:grid-cols-3'>
              <div>
                <img className='mx-auto pt-6' src={Image2} alt="" />
              </div>
              <div className='lg:col-span-2'>
                <div className='mx-[5%] lg:text-left text-center'>
                  <p className='heading5 text-rot3-malachite'>We Are Specializing In</p>
                  <p className='heading9 pb-8'>Every idea demands a unique response! We specialize in assisting you in turning your strategy into successful ideas with our exceptional products.</p>
                  <p className='heading5 text-rot3-deep-green'>Our Skills</p>
                </div>
                <div>
                  <AnimatedBar props={'85'}/>
                  <AnimatedBar props={'74'}/>
                  <AnimatedBar props={'90'}/>
                  <AnimatedBar props={'90'}/>
                  <AnimatedBar props={'90'}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='grid lg:grid-cols-5 lg:text-left text-center text-rot3-deep-green'>
            <div className='bg-[#ECFFF7] p-4 pt-12 pr-10'>
              <p className='heading2'>1</p>
              <p className='heading5'>How do we turn your idea into reality?</p>
              <p className='heading9'>We hand over full-fledged solutions to you</p>
            </div>

            <div className='bg-[#CBFFEA] p-4 pt-12 pr-10'>
              <p className='heading2'>2</p>
              <p className='heading5'>Share Your Business Idea</p>
              <p className='heading9'>Turn your ideas into a profitable business model by initiating your development process.</p>
            </div>

            <div className='bg-[#9EFFD7] p-4 pt-12 pr-10'>
              <p className='heading2'>3</p>
              <p className='heading5'>Ideas Project Draft</p>
              <p className='heading9'>We start the project process by turning your idea into a project and making it an applicable and scalable draft.</p>
            </div>

            <div className='bg-[#67FCBF] p-4 pt-12 pr-10'>
              <p className='heading2'>4</p>
              <p className='heading5'>From Draft to Product</p>
              <p className='heading9'>Deciding on the technical, design and financial infrastructure necessary for the implementation of the drafted project and transforming the process into a product.</p>
            </div>

            <div className='bg-[#48EEAA] p-4 pt-12 pr-10'>
              <p className='heading2'>5</p>
              <p className='heading5'>Testing & Delivery</p>
              <p className='heading9'>Completing the delivery process to the user by making the documentation and tests of the project converted into a product to the finest detail, and being by the customer's side by providing technical support throughout the entire process.</p>
            </div>
          </div>
        </div>
    </>
  )
}
