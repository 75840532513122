import { useState } from 'react';

import { BannerContainer } from '../components/BannerContainer';
import Marketing2Banner from '../assets/images/marketing2-banner.png'

import ProfessionalDesign from '../assets/images/professionally-designed.svg'
import ProfessionalDesign2 from '../assets/images/professionally-designed2.svg'
import Affordable from '../assets/images/affordable.svg'
import Affordable2 from '../assets/images/affordable2.svg'
import Simple from '../assets/images/simple.svg'
import Simple2 from '../assets/images/simple2.svg'

import PlayFiverr from '../assets/icons/play-fiverr.svg'


import WordPress from '../assets/icons/wordpress.svg'
import CustomWebsites from '../assets/icons/custom-websites.svg'
import Shopify from '../assets/icons/shopify.svg'
import Wix from '../assets/icons/wix.svg'
import Woo from '../assets/icons/woo.svg'

import WebFlow from '../assets/icons/webflow.svg'
import SquareSpace from '../assets/icons/squareSpace.svg'
import ClickFunnels from '../assets/icons/clickFunnels.svg'
import GoDady from '../assets/icons/goDady.svg'


import Img1 from '../assets/images/page1-image.png'
import Img2 from '../assets/images/page2-image.png'
import Img3 from '../assets/images/page3-image.png'
import Img4 from '../assets/images/page4-image.png'
import Img5 from '../assets/images/page5-image.png'

import CompanyBrand from '../assets/images/company-branding.png'
import FormBackGround from '../assets/images/form-background.png'

export const Marketing2 = () => {

    const [whichPage="1", setWhichPage] = useState('1');
    
    const options = {


        image: Marketing2Banner,
        container: {
            width: 'lg:w-1/2 w-full',
            padding: 'lg:h-[500px]',
            marginY: 'my-20',
            margin: 'lg:ml-36'
        },
        header: {
            text: 'Make a professional logo in a few clicks',
            size: 'sm:heading2 heading2',
            align: 'lg:text-left text-center',
            color: 'text-rot3-deep-green'
        },
        subHeader: {
            text: 'Type your brand name',
            size: 'heading9',
            align: 'lg:text-left text-center',
            bold: 'text-bold',
            margin : 'mb-10',
            color: 'text-rot3-deep-green'
        },
        button: {
            padding: "lg:float-left",
            textcolor: "text-rot3-deep-green",
            hover: "hover:text-white bg-rot3-malachite text-white hover:text-white rounded-none",
            text: "Make Your Logo",
        }
    }
  return (
    <>
    <BannerContainer options={options} />

    <div className='py-16'>
        <p className='heading4 text-rot3-deep-green text-center'>How Does the Logo Maker work?</p>
        <hr className='border-1 border-rot3-deep-green w-7/12 mx-auto my-8' />

        { 
          whichPage == '1' && ( 
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center items-center'>
                  <div className='lg:ml-16'>
                    <div onClick={()=> setWhichPage('1')} className='divCircle text-white pl-4 heading5 bg-rot3-deep-green'>1</div>
                    <div onClick={()=> setWhichPage('2')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>2</div>
                    <div onClick={()=> setWhichPage('3')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>3</div>
                    <div onClick={()=> setWhichPage('4')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>4</div>
                    <div onClick={()=> setWhichPage('5')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>5</div>
                    <p className='body3 pt-3'>Step 1</p>
                    <p className='heading8 text-rot3-malachite py-2'>First, tell us about your business</p>
                    <p className='body3'>Start igniting your brand identity by helping us learn about your business, your industry, and your services so we can create the right logo for your brand.</p>
                  </div>
                  <div>
                      <img className='mx-auto' src={Img1} alt="" />
                  </div>
                </div>
          )
        }

        { 
          whichPage == '2' && ( 
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center items-center'>
                  <div className='lg:ml-16'>
                    <div onClick={()=> setWhichPage('1')} className='divCircle text-white pl-4 heading5 bg-[#D9D9D9]'>1</div>
                    <div onClick={()=> setWhichPage('2')} className='divCircle text-white pl-3 heading5 bg-rot3-deep-green ml-2'>2</div>
                    <div onClick={()=> setWhichPage('3')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>3</div>
                    <div onClick={()=> setWhichPage('4')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>4</div>
                    <div onClick={()=> setWhichPage('5')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>5</div>
                    <p className='body3 pt-3'>Step 2</p>
                    <p className='heading8 text-rot3-malachite py-2'>Next, define your look & feel</p>
                    <p className='body3'>Your logo should reflect your brand's story at a glance. Customize and share your style preferences and brand values to help us create a memorable customized logo for your brand.</p>
                  </div>
                  <div>
                      <img className='mx-auto' src={Img2} alt="" />
                  </div>
                </div>
          )
        }

        { 
          whichPage == '3' && ( 
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center items-center'>
                  <div className='lg:ml-16'>
                    <div onClick={()=> setWhichPage('1')} className='divCircle text-white pl-4 heading5 bg-[#D9D9D9]'>1</div>
                    <div onClick={()=> setWhichPage('2')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>2</div>
                    <div onClick={()=> setWhichPage('3')} className='divCircle text-white pl-3 heading5 bg-rot3-deep-green ml-2'>3</div>
                    <div onClick={()=> setWhichPage('4')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>4</div>
                    <div onClick={()=> setWhichPage('5')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>5</div>
                    <p className='body3 pt-3'>Step 3</p>
                    <p className='heading8 text-rot3-malachite py-2'>Now, let us do the rest</p>
                    <p className='body3'>We'll instantly generate logo designs that fit your business and style, based on a wide selection of logo designs made by talented designers on Fiverr.</p>
                  </div>
                  <div>
                      <img className='mx-auto' src={Img3} alt="" />
                  </div>
                </div>
          )
        }

        { 
          whichPage == '4' && ( 
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center items-center'>
                  <div className='lg:ml-16'>
                    <div onClick={()=> setWhichPage('1')} className='divCircle text-white pl-4 heading5 bg-[#D9D9D9]'>1</div>
                    <div onClick={()=> setWhichPage('2')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>2</div>
                    <div onClick={()=> setWhichPage('3')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>3</div>
                    <div onClick={()=> setWhichPage('4')} className='divCircle text-white pl-3 heading5 bg-rot3-deep-green ml-2'>4</div>
                    <div onClick={()=> setWhichPage('5')} className='divCircle text-white pl-3 heading5 bg-[#D9D9D9] ml-2'>5</div>
                    <p className='body3 pt-3'>Step 4</p>
                    <p className='heading8 text-rot3-malachite py-2'>Want to add your personal touch?</p>
                    <p className='body3'>Browse the different custom logo designs made by real professional designers and choose yours as is, or customize the colors, layout, fonts, shapes and backgrounds, with our simple-to-use editing tool. You can even save your Logo and get back to it later at any time.</p>
                  </div>
                  <div>
                      <img className='mx-auto' src={Img4} alt="" />
                  </div>
                </div>
          )
        }

        { 
          whichPage == '5' && ( 
                <div className='grid lg:grid-cols-2 gap-8 lg:text-left text-center items-center'>
                  <div className='lg:ml-16'>
                    <div onClick={()=> setWhichPage('1')} className='divCircle text-white lg:pl-4 heading5 bg-[#D9D9D9]'>1</div>
                    <div onClick={()=> setWhichPage('2')} className='divCircle text-white lg:pl-3 heading5 bg-[#D9D9D9] ml-2'>2</div>
                    <div onClick={()=> setWhichPage('3')} className='divCircle text-white lg:pl-3 heading5 bg-[#D9D9D9] ml-2'>3</div>
                    <div onClick={()=> setWhichPage('4')} className='divCircle text-white lg:pl-3 heading5 bg-[#D9D9D9] ml-2'>4</div>
                    <div onClick={()=> setWhichPage('5')} className='divCircle text-white lg:pl-3 heading5 bg-rot3-deep-green ml-2'>5</div>
                    <p className='body3 pt-3'>Step 5</p>
                    <p className='heading8 text-rot3-malachite py-2'>Use your logo everywhere</p>
                    <p className='body3'>Love your logo? Now download it and use it across all your printed and digital assets. Choose a branding package with everything you need for your business - from brand guidelines to print quality files to a complete social media kit.</p>
                  </div>
                  <div>
                      <img className='mx-auto' src={Img5} alt="" />
                  </div>
                </div>
          )
        }
      </div>

    <hr className="border-1 w-[80%] mx-auto mb-4 mt-20"></hr>

    
    <div>
    <div className=" py-20 lg:px-40">
    <div className='grid lg:grid-cols-3 md:grid-cols-2  gap-y-20 gap-x-20 text-center p-6 items-center'>

        <div className='flex flex-col items-center'>
            <img className='' src={ProfessionalDesign} alt="" />
            <div className='textArea '>
            <h2 className='heading8 text-rot3-deep-green'>It's Professionally Designed</h2> 
            <p className='text-rot3-deep-green body1'>Every logo was created by a top-tier logo designer, hand-picked by Fiverr's Editorial Team. That's why all of our logos look so good.</p>
            </div>
        </div>

        <div className='flex flex-col items-center'>
            <img className='' src={Affordable} alt="" />
            <div className='textArea'>
            <h2 className='heading8 text-rot3-deep-green'>It's Affordable</h2> 
            <p className='text-rot3-deep-green body1'>It's free to explore hundreds of different options. Then purchase a logo that leaves an impact, not an imprint on your wallet.</p>
            </div>
        </div>

        <div className='flex flex-col items-center'>
            <img className='' src={Simple} alt="" />
            <div className='textArea'>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>It's Simple</h2> 
            <p className='text-rot3-deep-green body1 '>Answer a few questions about your business and be presented with diverse logo designs. It couldn't be easier.</p>
            </div>
        </div>

        <div className='flex flex-col items-center'>
            <img className='' src={ProfessionalDesign2} alt="" />
            <div className='textArea'>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>It's Professionally Designed</h2> 
            <p className='text-rot3-deep-green body1 '>Every logo was created by a top-tier logo designer, hand-picked by Fiverr's Editorial Team. That's why all of our logos look so good.</p>
            </div>
        </div>

        <div className='flex flex-col items-center'>
            <img className='' src={Affordable2} alt="" />
            <div className='textArea'>
            <h2 className='heading8 mb-4 text-rot3-deep-green'>It's Affordable</h2> 
            <p className='text-rot3-deep-green body1 '>It's free to explore hundreds of different options. Then purchase a logo that leaves an impact, not an imprint on your wallet.</p>
            </div>
        </div>

        <div className='flex flex-col items-center'>
        <img className='' src={Simple2} alt="" />
        <div className='textArea'>
        <h2 className='heading8 mb-4 text-rot3-deep-green'>It's Simple</h2> 
        <p className='text-rot3-deep-green body1'>Answer a few questions about your business and be presented with diverse logo designs. It couldn't be easier.</p>
        </div>
    </div>

    </div>
    
    </div>
    </div>

    <hr className="border-1 w-[80%] mx-auto mb-4 "></hr>

    <div className="webDevelopment py-20 lg:px-40">
        <h3 className='heading5 lg:text-left text-center'>Website Development</h3>
        <div className='flex gap-2 lg:text-left text-center'>
          <p className='opacity-50'>Create, build, and develop your website |  </p> <a href='#'> <img className='inline-block' src={PlayFiverr} alt="" /> <b>How Fiverr Works</b></a>
        </div>

        <div className='grid lg:grid-cols-5 gap-4 py-14 lg:mx-0 mx-20'>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto'  src={WordPress} alt="WordPress" />
            <p className='lg:text-left text-center body2'>WordPress</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={CustomWebsites} alt="WordPress" />
            <p className='lg:text-left text-center body2'>Custom Websites</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={Shopify} alt="WordPress" />
            <p className='lg:text-left text-center body2'>Shopify</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={Wix} alt="WordPress" />
            <p className='lg:text-left text-center body2'>Wix</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={Woo} alt="WordPress" />
            <p className='lg:text-left text-center body2'>WooCommerce</p>
          </div>

        </div>

        <div className='grid lg:grid-cols-4 gap-4 pb-14 lg:mx-40 mx-20'>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={WebFlow} alt="WordPress" />
            <p className='lg:text-left text-center body2'>Webflow</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={SquareSpace} alt="WordPress" />
            <p className='lg:text-left text-center body2'>Squarespace</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={ClickFunnels} alt="WordPress" />
            <p className='lg:text-left text-center body2'>ClickFunnels</p>
          </div>

          <div className='border border-black border-1 border-solid rounded-lg p-5'>
            <img className='lg:m-0 mx-auto' src={GoDady} alt="WordPress" />
            <p className='lg:text-left text-center body2'>GoDaddy</p>
          </div>

        </div>

    
    </div>

    <div className="companyBranding">

        <div className={"container-full grid  items-center lg:text-left text-center gap-4 p-14 z-0 relative bg-rot3-deep-green bg-no-repeat bg-cover"} style={{ backgroundImage: `url("${CompanyBrand}")` }}>
        <p className='heading2 py-8 text-white text-center' style={{textShadow:"-0.01em -0.01em 0.01em #003C3C"}}>COMPANY BRANDING</p>
        </div>

        <div className="texts py-7 lg:px-[187px] px-4 heading9 text-[#003C3C]">

        <h5 className='heading8'>Professional Website</h5>
        <p>
        The first impression is everything and your forex company website is literally where people see you the first time and keep in mind what they see. Professional, profitable, effective, classy, consistent. If you want to be noticed among your competitors, you should be aware of the vitality of a professional website. <br /><br />

        ROT3 Tech offers to develop a website that projects the professional image for your brokerage and eventually helps to improve the image of your company brand in the eyes of your customer. It is time to increase your influence through your online presence and virtual visibility. <br /><br />
        
        Let’s be honest, no one wants to use an outdated website. A modern, effective, and user-friendly website will not only show the personality of your company, but also be a hub to show your success and attract more clients. <br /><br />
        </p>
        
        <h3 className='heading8 text-rot3-deep-green mb-5'>Some reasons why you need a well established and functional website:</h3>
        <ul className='list-disc pl-12 mb-10 heading9 text-rot3-deep-green'>
            <li>Best way of online marketing</li>
            <li>Allows everyone to see your business in the online FX market</li>
            <li>Long-lasting and positive first impression</li>
            <li>It increases your organization's credibilitys</li>
            <li>It increases your chances of getting leads, your future clients</li>
            <li>Better and easier customer service</li>
            <li>Easiest way to update the world about your business</li>
         
        </ul>
        <h3 className='heading8 text-rot3-deep-green mb-5'>What we provide:</h3>
        <ul className='list-disc pl-12 mb-10 heading9 text-rot3-deep-green'>
           <li>Fully responsive website matching with the industry standards of usage</li>
           <li>Whole Setup: Domain, Hosting, and SSL Certificates</li>
           <li>Integration of the CRM and registration forms</li>
           <li>Mobile and tablet optimization</li>
           <li>Graphic design that embraces the logo, colours and the brand</li>
           <li>Efficient marketing materials</li>
           <li>Building social platforms</li>
           <li>Onsite SEO optimization to attract genuine clients</li>
           <li>Optionally fast changes</li>
           <li>Continuous updates and development support</li>
         
        </ul>
        </div>

      
    </div>

    <div className="formArea mb-8">
    <div className={"container-full grid  items-center lg:text-left text-center gap-4 p-14 z-0 relative bg-rot3-deep-green bg-no-repeat bg-cover"} style={{ backgroundImage: `url("${FormBackGround}")` }}>
        <div className='grid lg:grid-cols-2 gap-8 items-center'>
        <div>
         <p className='heading2 text-white max-w-[650px]'>Request free demo for any product or service!</p>
         </div>
            <div className="form">
            <p className='body4 text-white mb-4'>Fill the form below to get free trial:</p>
                <form action="#" className='grid gap-y-4 body3'>
                    <input className='pl-2 rounded-lg p-3' name="name" placeholder='Your Name'/>
                    <input className='pl-2 rounded-lg p-3' name="email" type='email'placeholder='Email' />
                    <input className='pl-2 rounded-lg p-3' name="phone" type='tel' placeholder='Phone Number' />
                    <button className='border border-1  text-white py-4 w-1/2 mx-auto' type='submit'>SUBMIT</button>
                </form>
            </div>

        </div>
    </div>


    
    </div>



    </>
  )
}
